import { Component, Input } from '@angular/core';
import { BadgeComponent } from '../badge/badge.component';
import { PriceComponent } from '../price/price.component';

@Component({
  selector: 'app-total-amount',
  standalone: true,
  imports: [
    PriceComponent,
    BadgeComponent,
  ],
  templateUrl: './total-amount.component.html',
  styleUrl: './total-amount.component.scss',
})
export class TotalAmountComponent {
  @Input() label!: string;
  @Input() amount!: number;
}
