import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDateFormats, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, Subscription, switchMap, throwError } from 'rxjs';
import { ProductModel } from '../../core/rest/products/model/product.model';
import { ColorPaletteComponent } from '../../shared/components/helper/color-palette/color-palette.component';
import { ButtonComponent } from '../../shared/components/ui/button/button.component';
import { PriceComponent } from '../../shared/components/ui/price/price.component';
import { TotalAmountComponent } from '../../shared/components/ui/total-amount/total-amount.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ProductsRestService } from '../../core/rest/products/products-rest.service';
import { CartRestService } from '../../core/rest/cart/cart-rest.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { CustomerRestService } from '../../core/rest/customer/customer-rest.service';
import { SnackBarService } from '../../core/services/snackbar.service';
import { CheckoutTotalAmountService } from './checkout-total-amount.service';
import { CartProductModel } from '../../core/rest/cart/model/cart-product.model';
import { TransformDatePipe } from '../../shared/pipes/transform-date.pipe';

export const MAT_DATE_FORMAT = 'DD/MM/YYYY';

const CUSTOM_DATETIME_FORMATS: MatDateFormats = {
  parse: {
    dateInput: MAT_DATE_FORMAT,
  },
  display: {
    dateInput: MAT_DATE_FORMAT,
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    ProductItemComponent,
    ButtonComponent,
    PriceComponent,
    AsyncPipe,
    NgForOf,
    ColorPaletteComponent,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatDatepickerModule,
    RouterLink,
    TotalAmountComponent,
    TranslateModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    NgIf,
    TransformDatePipe,
  ],
  providers: [
    provideMomentDateAdapter(CUSTOM_DATETIME_FORMATS),
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss',
})

export class CheckoutComponent implements OnInit, OnDestroy {
  products: ProductModel[] = [];
  dayToday = new Date();
  form!: FormGroup;
  checkoutTotalAmountService = inject(CheckoutTotalAmountService);
  snackBarService = inject(SnackBarService);
  customerRestService = inject(CustomerRestService);
  cartRestService = inject(CartRestService);
  productsRestService = inject(ProductsRestService);
  productSubscription$!: Subscription;
  ticketValidityPeriod$ = this.productsRestService.getTicketValidityPeriod();

  @Input() labelPosition: any = 'before';

  ngOnInit(): void {

    this.initializeForm();

    this.productSubscription$ = this.productsRestService.getProducts()
      .pipe(
        switchMap(products => {
          return this.cartRestService.getCart()
            .pipe(
              catchError(error => {
                return throwError(() => error);
              }),
              switchMap(cart => {
                products.forEach(product => {
                  if (!product.quantity) {
                    product.quantity = 0;
                  }
                });
                if (cart.items.length) {
                  this.setProductsFromCart(cart.items, products);
                } else {
                  this.checkoutTotalAmountService.updateProducts(products);
                }

                return [];
              })
            );
        }),
        catchError(err => {
          const message = this.customerRestService.getErrorMessage(err);
          this.snackBarService.showErrorMessage(message);
          return throwError(() => err);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.productSubscription$) {
      this.productSubscription$.unsubscribe();
    }
  }

  setProductsFromCart(cartItems: CartProductModel[], products: ProductModel[]): void {
    const updatedProducts = products.map(product => {
      const cartProduct = cartItems.find(cartItem => cartItem.idProduct === product.id);
      return {
        ...product,
        quantity: cartProduct ? cartProduct.quantity : 0
      };
    });

    this.checkoutTotalAmountService.updateProducts(updatedProducts);
  }
  initializeForm(): void {
    this.form = new FormGroup({
      departureDate: new FormControl({value: new Date(), disabled: true}),
    });
  }
}
