<div class="c-price"
  [ngClass]="[
    'c-price--symbol-position-' + symbolPosition,
    'c-price--color-' + color,
  ]">
  @if (showSymbol) {
    <div class="c-price__symbol">{{ symbol }}</div>
  }

  <div class="c-price__amount">{{ amountStr }}</div>

  @if (showCurrency) {
    <div class="c-price__currency">{{ currency }}</div>
  }
</div>
