<div class="c-phone-field">
  <mat-label>{{ label }}<span class="u-form-element-required-star" *ngIf="required">*</span></mat-label>
  <mat-form-field appearance="outline"
    [ngClass]="[
    isFullWide ? 'u-form-field-full' : '',
  ]">
    <ngx-mat-intl-tel-input
      [preferredCountries]="['me', 'gb']"
      name="phone"
      [enablePlaceholder]="true"
      [placeholder]="placeholder"
      [required]="required"
      [enableSearch]="true"
      [formControl]="$any(ngControl.control)">
    </ngx-mat-intl-tel-input>
  </mat-form-field>
  @if (ngControl.control?.invalid && ngControl.control?.touched) {
    <mat-error *ngIf="ngControl.control?.errors?.['required']">{{ 'VALIDATIONS.FIELD_REQUIRED' | translate }}</mat-error>
    <mat-error
      *ngIf="ngControl.control?.errors?.['validatePhoneNumber']">{{ 'VALIDATIONS.PHONE_NUMBER_INVALID' | translate }}
    </mat-error>
  }
</div>
