<button class="c-button"
  [ngClass]="[
    'c-button--size-' + size,
    'c-button--shape-' + shape,
    'c-button--style-' + style,
    'c-button--color-' + color,
    'c-button--font-size-' + fontSize,
    !!imgSrc ? 'c-button--with-img' : '',
    !!icon && !!label && !hasDropdown ? 'c-button--with-icon-and-label' : '',
    !!icon && !!label && hasDropdown ? 'c-button--with-icon-and-label-has-dropdown' : '',
    !icon && !!label && !hasDropdown ? 'c-button--with-label' : '',
    !icon && !!label && hasDropdown ? 'c-button--with-label-has-dropdown' : '',
    !!icon && !label && !hasDropdown ? 'c-button--with-icon' : '',
    !!icon && !label && hasDropdown ? 'c-button--with-icon-has-dropdown' : '',
    isFullWidth ? 'c-button--full-width' : '',
    textUppercase ? 'c-button--text-uppercase' : ''
  ]"
  [disabled]="disabled"
  (click)="onClick($event)">

  @if (imgSrc && !icon) {
    <img class="c-button__img" [src]="imgSrc" alt="">
  }

  @if (label) {
    <span class="c-button__label">{{ label }}</span>
  }

  @if (icon && !imgSrc) {
    <span class="c-button__icon">
      <app-icon [name]="icon"></app-icon>
    </span>
  }

  @if (hasDropdown) {
    <span class="c-button__dropdown-icon">
      <span class="material-symbols-outlined">arrow_drop_down</span>
    </span>
  }
</button>
