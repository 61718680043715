import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDualZoneBody]',
  standalone: true,
})
export class DualZoneBodyDirective {

  constructor(public tplRef: TemplateRef<any>) { }

}
