import { NgClass } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatIcon } from "@angular/material/icon";
import { IconComponent } from '../icon/icon.component';

export type ButtonSize = 's' | 'm';
export type ButtonShape = 'rectangle' | 'rounded' | 'circle';
export type ButtonStyle = 'flat' | 'naked' | 'outlined';
export type ButtonColor = 'primary' | 'primary-weak' | 'secondary' | 'neutral' | 'neutral-inverted' | 'danger' | 'success' | 'warning' | 'info' | 'link';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    NgClass,
    MatIcon,
    IconComponent,
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent implements OnChanges {
  @Input() icon?: string;
  @Input() label?: string;
  @Input() imgSrc?: string | null;
  @Input() hasDropdown = false;
  @Input() size: ButtonSize = 'm';
  @Input() shape: ButtonShape = 'rounded';
  @Input() style: ButtonStyle = 'flat';
  @Input() color: ButtonColor = 'primary';
  @Input() disabled = false;
  @Input() fontSize = 'l';
  @Input() textUppercase = true;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();
  @HostBinding('class.is-full-wide') @Input() isFullWidth = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imgSrc']) {
      // In case of using image in the button,
      // we don't want to show the label nor the icon nor the dropdown.
      this.label = undefined;
      this.icon = undefined;
      this.hasDropdown = false;
    }
  }

  onClick(event: any): void {
    if (!this.disabled) {
      this.clickEvent.emit(event);
    }
  }
}
