import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild } from '@angular/core';
import { TripleZoneBodyDirective } from './triple-zone-body.directive';
import { TripleZoneFooterDirective } from './triple-zone-footer.directive';
import { TripleZoneHeaderDirective } from './triple-zone-header.directive';

@Component({
  selector: 'app-triple-zone',
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
  ],
  templateUrl: './triple-zone.component.html',
  styleUrl: './triple-zone.component.scss',
})
export class TripleZoneComponent {
  @ContentChild(TripleZoneHeaderDirective) headerDirective?: TripleZoneHeaderDirective;
  @ContentChild(TripleZoneBodyDirective) bodyDirective?: TripleZoneBodyDirective;
  @ContentChild(TripleZoneFooterDirective) footerDirective?: TripleZoneFooterDirective;
}
