import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TripleZoneBodyDirective } from '../../shared/components/layouts/triple-zone/triple-zone-body.directive';
import { TripleZoneFooterDirective } from '../../shared/components/layouts/triple-zone/triple-zone-footer.directive';
import { TripleZoneHeaderDirective } from '../../shared/components/layouts/triple-zone/triple-zone-header.directive';
import { TripleZoneComponent } from '../../shared/components/layouts/triple-zone/triple-zone.component';
import { ButtonComponent } from '../../shared/components/ui/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { LogoComponent } from '../../shared/components/ui/logo/logo.component';
import { CartRestService } from '../../core/rest/cart/cart-rest.service';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NgIf } from '@angular/common';
import { IconComponent } from '../../shared/components/ui/icon/icon.component';
import { ProgressSpinnerComponent } from '../../shared/components/ui/progress-spinner/progress-spinner.component';

@Component({
  selector: 'app-payment-confirmation',
  standalone: true,
  imports: [
    RouterLink,
    ButtonComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    TripleZoneBodyDirective,
    TripleZoneComponent,
    TripleZoneFooterDirective,
    TripleZoneHeaderDirective,
    TranslateModule,
    NgOptimizedImage,
    LogoComponent,
    NgIf,
    IconComponent,
    NgClass,
    ProgressSpinnerComponent,
  ],
  templateUrl: './payment-confirmation.component.html',
  styleUrl: './payment-confirmation.component.scss',
})
export class PaymentConfirmationComponent implements OnInit {
  private _route = inject(ActivatedRoute);
  successResponse!: string;
  downloadLink!: string;

  constructor(private cartRestService: CartRestService) {
  }

  ngOnInit(): void {
    const {
      shoppingCartId,
      dateTime,
      approvalCode,
      signature,
      errorMessage,
      stan,
      wsPayOrderId,
      success
    } = this.getParamsFromUrl();
    this.successResponse = success;
    const params = {
      successResponse: Number(this.successResponse),
      shoppingCartId: shoppingCartId,
      dateTime: dateTime,
      approvalCode: approvalCode,
      signature: signature,
      errorMessage: errorMessage,
      stan: stan,
      wsPayOrderId: wsPayOrderId,
    }
    this.cartRestService.sendPaymentProcessResponse(params)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
      .subscribe((response) => {
        if (response) {
          this.downloadLink = response.downloadLink;
        }
      });
  }

  getParamsFromUrl(): {
    shoppingCartId: string,
    dateTime: string,
    approvalCode: string,
    signature: string,
    errorMessage: string,
    stan: string,
    wsPayOrderId: string,
    success: string
  } {
    const shoppingCartId = this._route.snapshot.queryParams['ShoppingCartID'];
    const dateTime = this._route.snapshot.queryParams['DateTime'];
    const stan = this._route.snapshot.queryParams['STAN'];
    const wsPayOrderId = this._route.snapshot.queryParams['WsPayOrderId'];
    const success = this._route.snapshot.queryParams['Success'];
    const approvalCode = this._route.snapshot.queryParams['ApprovalCode'];
    const signature = this._route.snapshot.queryParams['Signature'];
    const errorMessage = this._route.snapshot.queryParams['ErrorMessage'];

    return {shoppingCartId, dateTime, approvalCode, signature, errorMessage, stan, wsPayOrderId, success}
  }

  downloadTicket(): void {
    window.open(this.downloadLink, '_blank');
  }
}
