import { NgClass } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

type Color = 'inherit' | 'secondary';

@Component({
  selector: 'app-price',
  standalone: true,
  imports: [
    NgClass,
  ],
  templateUrl: './price.component.html',
  styleUrl: './price.component.scss'
})
export class PriceComponent implements OnChanges {
  @Input() color: Color = 'inherit';
  @Input() symbol: string = '€';
  @Input() amount: number = 0;
  @Input() currency: string = 'EUR';
  @Input() symbolPosition: 'before' | 'after' = 'after';
  @Input() showSymbol = true;
  @Input() showCurrency = false;
  @HostBinding('class.u-inline-block') @Input() isInlineElement = false;
  amountStr?: string;

  ngOnChanges(changes:SimpleChanges): void {
    if (changes['amount']) {
      this.amountStr = this.formatPrice(this.amount);
    }
  }

  private formatPrice(amount: number): string {
    if (isNaN(amount)) {
      return '';
    }
    return (+amount).toLocaleString('sr-RS', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      minimumIntegerDigits: 1,
    });
  }
}
