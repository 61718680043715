import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { MatSelectModule } from "@angular/material/select";
import { MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { TranslateModule } from "@ngx-translate/core";
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-panel',
  standalone: true,
  imports: [
    NgClass,
    ButtonComponent,
    MatSelectModule,
    MatIconButton,
    MatIcon,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    TranslateModule,
    NgIf,
    IconComponent,
  ],
  templateUrl: './panel.component.html',
  styleUrl: './panel.component.scss',
})
export class PanelComponent implements OnChanges {
  @Input() title?: string;
  @Input() collapsible = false;
  @Input() filterBy: boolean = true;
  @Input() expanded = true;
  @Output() sortByEvent = new EventEmitter<string>();
  isSortDescending = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['collapsible']) {
      if (!this.collapsible) {
        this.expanded = true;
      }
    }
  }

  toggle(): void {
    if (this.collapsible) {
      this.expanded = !this.expanded;
    }
  }

  sort() {
    this.isSortDescending = !this.isSortDescending;
    if (this.isSortDescending) {
      this.sortByEvent.emit('date-desc');
    } else {
      this.sortByEvent.emit('date-asc');
    }
  }
}
