import { Component, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { TripleZoneBodyDirective } from '../../shared/components/layouts/triple-zone/triple-zone-body.directive';
import { TripleZoneFooterDirective } from '../../shared/components/layouts/triple-zone/triple-zone-footer.directive';
import { TripleZoneHeaderDirective } from '../../shared/components/layouts/triple-zone/triple-zone-header.directive';
import { TripleZoneComponent } from '../../shared/components/layouts/triple-zone/triple-zone.component';
import { ButtonComponent } from '../../shared/components/ui/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CustomerRestService } from "../../core/rest/customer/customer-rest.service";
import { NgIf } from "@angular/common";
import { catchError, throwError } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackBarService } from "../../core/services/snackbar.service";
import { LogoComponent } from '../../shared/components/ui/logo/logo.component';
import { EMAIL_PATTERN } from '../../core/utils/form-patterns';
import { IconComponent } from '../../shared/components/ui/icon/icon.component';

@Component({
  selector: 'app-password-reset',
  standalone: true,
  imports: [
    RouterLink,
    TripleZoneComponent,
    TripleZoneHeaderDirective,
    TripleZoneBodyDirective,
    TripleZoneFooterDirective,
    MatFormFieldModule,
    MatInputModule,
    ButtonComponent,
    TranslateModule,
    ReactiveFormsModule,
    NgIf,
    LogoComponent,
    IconComponent,
  ],
  templateUrl: './password-reset.component.html',
  styleUrl: './password-reset.component.scss'
})
export class PasswordResetComponent implements OnInit{

  form!: FormGroup;

  constructor(private customerRestService: CustomerRestService,
              private router: Router,
              private snackBarService: SnackBarService) {}

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)])
    })
  }

  sendResetEmail() {
    if(this.form.invalid) return;
    const email = this.form.get('email')?.value;
    this.customerRestService.resetPasswordEmail(email)
      .pipe(
        catchError((err: HttpErrorResponse)=> {
          const message = this.customerRestService.getErrorMessage(err);
          this.snackBarService.showErrorMessage(message);
          return throwError(() => err);
        })
      )
      .subscribe( () => {
        this.router.navigate(['password/reset/confirmation'])
      })
  }

  getEmailPattern() {
    return this.form.get('email')?.value.match(EMAIL_PATTERN);
  }
}
