<div class="c-password-reset">
  <div [formGroup]="form">
    <app-triple-zone>
      <ng-template appTripleZoneHeader>
        <div class="c-password-reset__logo">
          <app-logo [size]="'l'"></app-logo>
        </div>
      </ng-template>
      <ng-template appTripleZoneBody>
        <div class="c-password-reset__content">
          <span class="c-password-reset__content-icon">
            <app-icon [name]="'lock_reset'" [size]="'2xl'"></app-icon>
          </span>
          <div class="c-password-reset__content-text">
            <p>{{ 'PASSWORD_RESET.TITLE' | translate }}</p>
          </div>
        </div>
      </ng-template>
      <ng-template appTripleZoneFooter>
        <div class="u-form-element-wrapper">
          <mat-label>{{ 'COMMON.FORM_FIELDS.EMAIL.LABEL' | translate }} <span class="u-form-element-required-star">*</span></mat-label>
          <mat-form-field class="u-form-field-full" appearance="outline">
            <input type="text" formControlName="email" matInput [placeholder]="'COMMON.FORM_FIELDS.EMAIL.PLACEHOLDER' | translate">
          </mat-form-field>
          @if(form.get('email')?.invalid && form.get('email')?.touched) {
            <mat-error *ngIf="form.get('email')?.hasError('required')">
              {{'COMMON.FORM_FIELDS.EMAIL.REQUIRED_MESSAGE' | translate}}
            </mat-error>
            @if (!getEmailPattern()) {
              <mat-error>{{'COMMON.FORM_FIELDS.EMAIL.EMAIL_FORMAT_MESSAGE' | translate}}</mat-error>
            }
          }
        </div>

        <div class="u-form-element-wrapper">
          <app-button [label]="'COMMON.SEND' | translate"
            [isFullWidth]="true"
            [disabled]="!form.valid"
            (clickEvent)="sendResetEmail()">
          </app-button>
        </div>
      </ng-template>
    </app-triple-zone>
  </div>
</div>
