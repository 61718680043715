<div class="c-account">
  <app-dual-zone>
    <div [formGroup]="accountForm">
      <ng-template appDualZoneBody>
        <app-panel [title]="'ACCOUNT.PROFILE_SETTINGS' | translate"
          [collapsible]="false"
          [filterBy]="false">
          <div class="u-form-element-wrapper">
            <mat-label>{{ 'COMMON.FORM_FIELDS.FIRST_NAME.LABEL' | translate }} <span
              class="u-form-element-required-star">*</span></mat-label>
            <mat-form-field class="u-form-field-full" appearance="outline">
              <input type="text" formControlName="firstName" matInput
                placeholder="{{ 'COMMON.FORM_FIELDS.FIRST_NAME.LABEL' | translate }}">
            </mat-form-field>
            @if (accountForm.get('firstName')?.invalid && accountForm.get('firstName')?.touched) {
              <mat-error *ngIf="accountForm.get('firstName')?.hasError('required')">
                {{ 'COMMON.FORM_FIELDS.FIRST_NAME.REQUIRED_MESSAGE' | translate }}
              </mat-error>
            }
          </div>

          <div class="u-form-element-wrapper">
            <mat-label>{{ 'COMMON.FORM_FIELDS.LAST_NAME.LABEL' | translate }} <span
              class="u-form-element-required-star">*</span></mat-label>
            <mat-form-field class="u-form-field-full" appearance="outline">
              <input type="text" formControlName="lastName" matInput
                placeholder="{{ 'COMMON.FORM_FIELDS.LAST_NAME.PLACEHOLDER' | translate }}">
            </mat-form-field>
            @if (accountForm.get('lastName')?.invalid && accountForm.get('lastName')?.touched) {
              <mat-error *ngIf="accountForm.get('lastName')?.hasError('required')">
                {{ 'COMMON.FORM_FIELDS.LAST_NAME.REQUIRED_MESSAGE' | translate }}
              </mat-error>
            }
          </div>

          <div class="u-form-element-wrapper">
            <app-phone-field
              [label]="'COMMON.FORM_FIELDS.PHONE.LABEL' | translate"
              [placeholder]="'COMMON.FORM_FIELDS.PHONE.PLACEHOLDER' | translate"
              [isFullWide]="true"
              [formControlName]="'phone'">
            </app-phone-field>
          </div>

          <div class="u-form-element-wrapper">
            <mat-label>{{ 'COMMON.FORM_FIELDS.EMAIL.LABEL' | translate }}</mat-label>
            <mat-form-field class="u-form-field-full" appearance="outline">
              <input type="text" formControlName="email" matInput
                placeholder="{{ 'COMMON.FORM_FIELDS.EMAIL.PLACEHOLDER' | translate }}">
            </mat-form-field>
          </div>

          <div class="u-form-element-wrapper">
            <mat-label>{{ 'ACCOUNT.VEHICLE_CATEGORIES.LABEL' | translate }}</mat-label>
            <mat-form-field class="u-form-field-full" appearance="outline">
              <mat-select formControlName="idVehicle" [value]="2"
                [placeholder]="'ACCOUNT.VEHICLE_CATEGORIES.PLACEHOLDER' | translate">
                @for (product of products | sortByPrice; track product.id; ) {
                  <mat-option [value]="product.id">
                    <app-select-product-item [product]="product"></app-select-product-item>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </app-panel>
      </ng-template>
      <ng-template appDualZoneFooter>
        <app-button label="{{ 'COMMON.SAVE_CHANGES' | translate }}"
          [isFullWidth]="true"
          (clickEvent)="save()"
          [disabled]="!accountForm.valid">
        </app-button>
      </ng-template>
    </div>
  </app-dual-zone>
</div>
