import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  TemplateConfirmationMessageComponent
} from '../../shared/components/layouts/template-confirmation/template-confirmation-message/template-confirmation-message.component';
import {
  TemplateConfirmationComponent
} from '../../shared/components/layouts/template-confirmation/template-confirmation.component';
import { LogoComponent } from '../../shared/components/ui/logo/logo.component';

@Component({
  selector: 'app-registration-confirmation',
  standalone: true,
  imports: [
    TemplateConfirmationComponent,
    TemplateConfirmationMessageComponent,
    TranslateModule,
    LogoComponent,
  ],
  templateUrl: './registration-confirmation.component.html',
  styleUrl: './registration-confirmation.component.scss',
})
export class RegistrationConfirmationComponent {

}
