import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { routes } from './app.routes';
import { AppConfiguration } from './app-configuration';
import { AuthService } from "./core/rest/auth/auth.service";
import { SnackBarService } from "./core/services/snackbar.service";
import { CustomHttpInterceptor } from "./core/rest/custom-http-interceptor";

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

export const appConfig = (config: AppConfiguration) => ({
  providers: [
    {
      provide: AppConfiguration,
      useFactory: () => {
        if (!config.serverApiUrl.startsWith('http')) {
          config.serverApiUrl = `${window.location.origin}/${config.serverApiUrl}`;
        }
        return config;
      },
    },
    provideRouter(routes),
    provideAnimations(),
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
        verticalPosition: 'top',
        duration: 2500,
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      useDefaultLang: true,
      defaultLanguage: 'en',
    })),
    AuthService,
    SnackBarService
  ]
});
