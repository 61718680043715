<div class="c-terms">
  @if (currentLang === langCodesEnum.EN) {
    <ng-container [ngTemplateOutlet]="en"></ng-container>
  }
  @else if (currentLang === langCodesEnum.DE) {
    <ng-container [ngTemplateOutlet]="de"></ng-container>
  }
  @else if (currentLang === langCodesEnum.MN) {
    <ng-container [ngTemplateOutlet]="mn"></ng-container>
  }
  @else if (currentLang === langCodesEnum.RU) {
    <ng-container [ngTemplateOutlet]="ru"></ng-container>
  }
  @else {
    <p>There is no content for the selected language.</p>
  }
</div>

<ng-template #en>
  <h1>[EN] Terms and Conditions</h1>
  <p>Last Revised: December 16, 2013</p>
  <p><a href="">Welcome to www.lorem-ipsum.info.</a> This site is provided as a service to our visitors and may be used for
    informational purposes only. Because the Terms and Conditions contain legal obligations, please read them
    carefully.</p>
  <h2>1. YOUR AGREEMENT</h2>
  <p>By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree
    to these Terms and Conditions, please do not use this site.</p>
  <blockquote>PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these
    Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please
    review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes
    and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of
    these standards for notice of changes. For your information, this page was last updated as of the date at the top of
    these terms and conditions.
  </blockquote>
  <h3>2. PRIVACY</h3>
  <p>Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.</p>
  <h2>3. LINKED SITES</h2>
  <p>This Site may contain links to other independent third-party Web sites ("Linked Sites&#x201D;). These Linked Sites
    are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not
    responsible for and does not endorse the content of such Linked Sites, including any information or materials
    contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with
    these Linked Sites.</p>
  <ul>
    <li><strong>Some Item 1</strong></li>
    <li>Some Item 1</li>
    <li>Some Item 1</li>
  </ul>
  <ol>
    <li><strong>Some Item 1</strong></li>
    <li>Some Item 1</li>
    <li>Some Item 1</li>
  </ol>
  <h2>4. FORWARD LOOKING STATEMENTS</h2>
  <p>All materials reproduced on this site speak as of the original date of publication or filing. The fact that a
    document is available on this site does not mean that the information contained in such document has not been
    modified or superseded by events or by a subsequent document or filing. We have no duty or policy to update any
    information or statements contained on this site and, therefore, such information or statements should not be relied
    upon as being current as of the date you access this site.</p>
  <h2>5. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h2>
  <p>A. THIS SITE MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL ERRORS. WE DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF
    THE MATERIALS OR THE RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION DISPLAYED OR DISTRIBUTED
    THROUGH THE SITE. YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (i) YOUR USE OF THE SITE, INCLUDING ANY RELIANCE ON ANY
    SUCH OPINION, ADVICE, STATEMENT, MEMORANDUM, OR INFORMATION CONTAINED HEREIN, SHALL BE AT YOUR SOLE RISK; (ii) THE
    SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS; (iii) EXCEPT AS EXPRESSLY PROVIDED HEREIN WE DISCLAIM ALL
    WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO IMPLIED WARRANTIES OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT, TITLE AND NON-INFRINGEMENT; (iv) WE MAKE NO
    WARRANTY WITH RESPECT TO THE RESULTS THAT MAY BE OBTAINED FROM THIS SITE, THE PRODUCTS OR SERVICES ADVERTISED OR
    OFFERED OR MERCHANTS INVOLVED; (v) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DONE
    AT YOUR OWN DISCRETION AND RISK; and (vi) YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
    FOR ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</p>
  <p>B. YOU UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE BE
    LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF,
    OR THE INABILITY TO USE, ANY OF OUR SITES OR MATERIALS OR FUNCTIONS ON ANY SUCH SITE, EVEN IF WE HAVE BEEN ADVISED
    OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL
    PURPOSE OF ANY LIMITED REMEDY.</p>
  <h2>6. EXCLUSIONS AND LIMITATIONS</h2>
  <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
    INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, OUR LIABILITY IN SUCH JURISDICTION SHALL BE LIMITED TO THE MAXIMUM
    EXTENT PERMITTED BY LAW.</p>
  <h2>7. OUR PROPRIETARY RIGHTS</h2>
  <p>This Site and all its Contents are intended solely for personal, non-commercial use. Except as expressly provided,
    nothing within the Site shall be construed as conferring any license under our or any third party's intellectual
    property rights, whether by estoppel, implication, waiver, or otherwise. Without limiting the generality of the
    foregoing, you acknowledge and agree that all content available through and used to operate the Site and its
    services is protected by copyright, trademark, patent, or other proprietary rights. You agree not to: (a) modify,
    alter, or deface any of the trademarks, service marks, trade dress (collectively "Trademarks") or other intellectual
    property made available by us in connection with the Site; (b) hold yourself out as in any way sponsored by,
    affiliated with, or endorsed by us, or any of our affiliates or service providers; (c) use any of the Trademarks or
    other content accessible through the Site for any purpose other than the purpose for which we have made it available
    to you; (d) defame or disparage us, our Trademarks, or any aspect of the Site; and (e) adapt, translate, modify,
    decompile, disassemble, or reverse engineer the Site or any software or programs used in connection with it or its
    products and services.</p>
  <p>The framing, mirroring, scraping or data mining of the Site or any of its content in any form and by any method is
    expressly prohibited.</p>
  <h2>8. INDEMNITY</h2>
  <p>By using the Site web sites you agree to indemnify us and affiliated entities (collectively "Indemnities") and hold
    them harmless from any and all claims and expenses, including (without limitation) attorney's fees, arising from
    your use of the Site web sites, your use of the Products and Services, or your submission of ideas and/or related
    materials to us or from any person's use of any ID, membership or password you maintain with any portion of the
    Site, regardless of whether such use is authorized by you.</p>
  <h2>9. COPYRIGHT AND TRADEMARK NOTICE</h2>
  <p>Except our generated dummy copy, which is free to use for private and commercial use, all other text is
    copyrighted. generator.lorem-ipsum.info &#xA9; 2013, all rights reserved</p>
  <h2>10. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS</h2>
  <p>It is our policy to respond expeditiously to claims of intellectual property infringement. We will promptly process
    and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium
    Copyright Act ("DMCA") and other applicable intellectual property laws. Notices of claimed infringement should be
    directed to:</p>
  <p>generator.lorem-ipsum.info</p>
  <p>123 Some Street</p>
  <p>City, City</p>
  <p>Country</p>
  <p>Contact</p>
  <h2>11. PLACE OF PERFORMANCE</h2>
  <p>This Site is controlled, operated and administered by us from our office in Kiev, Ukraine. We make no
    representation that materials at this site are appropriate or available for use at other locations outside of the
    Ukraine and access to them from territories where their contents are illegal is prohibited. If you access this Site
    from a location outside of the Ukraine, you are responsible for compliance with all local laws.</p>
  <h2>12. GENERAL</h2>
  <p>A. If any provision of these Terms and Conditions is held to be invalid or unenforceable, the provision shall be
    removed (or interpreted, if possible, in a manner as to be enforceable), and the remaining provisions shall be
    enforced. Headings are for reference purposes only and in no way define, limit, construe or describe the scope or
    extent of such section. Our failure to act with respect to a breach by you or others does not waive our right to act
    with respect to subsequent or similar breaches. These Terms and Conditions set forth the entire understanding and
    agreement between us with respect to the subject matter contained herein and supersede any other agreement,
    proposals and communications, written or oral, between our representatives and you with respect to the subject
    matter hereof, including any terms and conditions on any of customer's documents or purchase orders.</p>
  <p>B. No Joint Venture, No Derogation of Rights. You agree that no joint venture, partnership, employment, or agency
    relationship exists between you and us as a result of these Terms and Conditions or your use of the Site. Our
    performance of these Terms and Conditions is subject to existing laws and legal process, and nothing contained
    herein is in derogation of our right to comply with governmental, court and law enforcement requests or requirements
    relating to your use of the Site or information provided to or gathered by us with respect to such use.</p>
</ng-template>

<ng-template #de>
  <h1>[DE] Terms and Conditions</h1>
  <p>Last Revised: December 16, 2013</p>
  <p><a href="">Welcome to www.lorem-ipsum.info.</a> This site is provided as a service to our visitors and may be used for
    informational purposes only. Because the Terms and Conditions contain legal obligations, please read them
    carefully.</p>
  <h2>1. YOUR AGREEMENT</h2>
  <p>By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree
    to these Terms and Conditions, please do not use this site.</p>
  <blockquote>PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these
    Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please
    review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes
    and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of
    these standards for notice of changes. For your information, this page was last updated as of the date at the top of
    these terms and conditions.
  </blockquote>
  <h3>2. PRIVACY</h3>
  <p>Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.</p>
  <h2>3. LINKED SITES</h2>
  <p>This Site may contain links to other independent third-party Web sites ("Linked Sites&#x201D;). These Linked Sites
    are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not
    responsible for and does not endorse the content of such Linked Sites, including any information or materials
    contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with
    these Linked Sites.</p>
  <ul>
    <li><strong>Some Item 1</strong></li>
    <li>Some Item 1</li>
    <li>Some Item 1</li>
  </ul>
  <ol>
    <li><strong>Some Item 1</strong></li>
    <li>Some Item 1</li>
    <li>Some Item 1</li>
  </ol>
  <h2>4. FORWARD LOOKING STATEMENTS</h2>
  <p>All materials reproduced on this site speak as of the original date of publication or filing. The fact that a
    document is available on this site does not mean that the information contained in such document has not been
    modified or superseded by events or by a subsequent document or filing. We have no duty or policy to update any
    information or statements contained on this site and, therefore, such information or statements should not be relied
    upon as being current as of the date you access this site.</p>
  <h2>5. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h2>
  <p>A. THIS SITE MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL ERRORS. WE DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF
    THE MATERIALS OR THE RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION DISPLAYED OR DISTRIBUTED
    THROUGH THE SITE. YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (i) YOUR USE OF THE SITE, INCLUDING ANY RELIANCE ON ANY
    SUCH OPINION, ADVICE, STATEMENT, MEMORANDUM, OR INFORMATION CONTAINED HEREIN, SHALL BE AT YOUR SOLE RISK; (ii) THE
    SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS; (iii) EXCEPT AS EXPRESSLY PROVIDED HEREIN WE DISCLAIM ALL
    WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO IMPLIED WARRANTIES OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT, TITLE AND NON-INFRINGEMENT; (iv) WE MAKE NO
    WARRANTY WITH RESPECT TO THE RESULTS THAT MAY BE OBTAINED FROM THIS SITE, THE PRODUCTS OR SERVICES ADVERTISED OR
    OFFERED OR MERCHANTS INVOLVED; (v) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DONE
    AT YOUR OWN DISCRETION AND RISK; and (vi) YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
    FOR ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</p>
  <p>B. YOU UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE BE
    LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF,
    OR THE INABILITY TO USE, ANY OF OUR SITES OR MATERIALS OR FUNCTIONS ON ANY SUCH SITE, EVEN IF WE HAVE BEEN ADVISED
    OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL
    PURPOSE OF ANY LIMITED REMEDY.</p>
  <h2>6. EXCLUSIONS AND LIMITATIONS</h2>
  <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
    INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, OUR LIABILITY IN SUCH JURISDICTION SHALL BE LIMITED TO THE MAXIMUM
    EXTENT PERMITTED BY LAW.</p>
  <h2>7. OUR PROPRIETARY RIGHTS</h2>
  <p>This Site and all its Contents are intended solely for personal, non-commercial use. Except as expressly provided,
    nothing within the Site shall be construed as conferring any license under our or any third party's intellectual
    property rights, whether by estoppel, implication, waiver, or otherwise. Without limiting the generality of the
    foregoing, you acknowledge and agree that all content available through and used to operate the Site and its
    services is protected by copyright, trademark, patent, or other proprietary rights. You agree not to: (a) modify,
    alter, or deface any of the trademarks, service marks, trade dress (collectively "Trademarks") or other intellectual
    property made available by us in connection with the Site; (b) hold yourself out as in any way sponsored by,
    affiliated with, or endorsed by us, or any of our affiliates or service providers; (c) use any of the Trademarks or
    other content accessible through the Site for any purpose other than the purpose for which we have made it available
    to you; (d) defame or disparage us, our Trademarks, or any aspect of the Site; and (e) adapt, translate, modify,
    decompile, disassemble, or reverse engineer the Site or any software or programs used in connection with it or its
    products and services.</p>
  <p>The framing, mirroring, scraping or data mining of the Site or any of its content in any form and by any method is
    expressly prohibited.</p>
  <h2>8. INDEMNITY</h2>
  <p>By using the Site web sites you agree to indemnify us and affiliated entities (collectively "Indemnities") and hold
    them harmless from any and all claims and expenses, including (without limitation) attorney's fees, arising from
    your use of the Site web sites, your use of the Products and Services, or your submission of ideas and/or related
    materials to us or from any person's use of any ID, membership or password you maintain with any portion of the
    Site, regardless of whether such use is authorized by you.</p>
  <h2>9. COPYRIGHT AND TRADEMARK NOTICE</h2>
  <p>Except our generated dummy copy, which is free to use for private and commercial use, all other text is
    copyrighted. generator.lorem-ipsum.info &#xA9; 2013, all rights reserved</p>
  <h2>10. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS</h2>
  <p>It is our policy to respond expeditiously to claims of intellectual property infringement. We will promptly process
    and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium
    Copyright Act ("DMCA") and other applicable intellectual property laws. Notices of claimed infringement should be
    directed to:</p>
  <p>generator.lorem-ipsum.info</p>
  <p>123 Some Street</p>
  <p>City, City</p>
  <p>Country</p>
  <p>Contact</p>
  <h2>11. PLACE OF PERFORMANCE</h2>
  <p>This Site is controlled, operated and administered by us from our office in Kiev, Ukraine. We make no
    representation that materials at this site are appropriate or available for use at other locations outside of the
    Ukraine and access to them from territories where their contents are illegal is prohibited. If you access this Site
    from a location outside of the Ukraine, you are responsible for compliance with all local laws.</p>
  <h2>12. GENERAL</h2>
  <p>A. If any provision of these Terms and Conditions is held to be invalid or unenforceable, the provision shall be
    removed (or interpreted, if possible, in a manner as to be enforceable), and the remaining provisions shall be
    enforced. Headings are for reference purposes only and in no way define, limit, construe or describe the scope or
    extent of such section. Our failure to act with respect to a breach by you or others does not waive our right to act
    with respect to subsequent or similar breaches. These Terms and Conditions set forth the entire understanding and
    agreement between us with respect to the subject matter contained herein and supersede any other agreement,
    proposals and communications, written or oral, between our representatives and you with respect to the subject
    matter hereof, including any terms and conditions on any of customer's documents or purchase orders.</p>
  <p>B. No Joint Venture, No Derogation of Rights. You agree that no joint venture, partnership, employment, or agency
    relationship exists between you and us as a result of these Terms and Conditions or your use of the Site. Our
    performance of these Terms and Conditions is subject to existing laws and legal process, and nothing contained
    herein is in derogation of our right to comply with governmental, court and law enforcement requests or requirements
    relating to your use of the Site or information provided to or gathered by us with respect to such use.</p>
</ng-template>

<ng-template #mn>
  <h1>Opšti uslovi kupovine</h1>
  <p>Ovo obavještenje pripremljeno je u skladu sa članovima 61-74j Zakona o zaštiti potrošača i ima za cilj da upozna potrošača sa pravima i obavezama iz ugovora na daljinu i ugovora van poslovnih prostorija (u daljem tekstu: ugovori), prije njihovog zaključenja. Obavještenje je objavljeno na internet stranici trgovca www.morskodobro.me a dostupno je potrošačima na svim prodajnim mjestima trgovca kao i elektonskim putem nakon obraćanja potrošača nadležnoj službi trgovca.</p>
  <p>Ovim Opštim uslovima kupovine utvrđuju se uslovi i način online kupovine karte za trajektni prevoz na liniji Kamenari – Lepetane odnosno zaključivanje ugovora na daljinu putem elektronske prodavnice (Web Shop).</p>
  <p><strong>Trgovac</strong>, u smislu ovih Opštih uslova kupovine, je pravno lice u državnoj svojini koje samostalno ili preko drugog lica koje nastupa u njegovo ime ili za njegov račun, zaključuje pravni posao ili djeluje na tržištu u okviru svoje trgovinske, poslovne, zanatske ili profesionalne djelatnosti.</p>
  <p><strong>Trgovac</strong>:<br>
    Javno preduzeće za upravljanje morskim dobrom Crne Gore<br>
    Popa Jola Zeca bb<br>
    85310 Budva<br>
    Crna Gora<br>
    PIB: 02116146<br>
    Registarski broj: 80010870<br>
    Broj telefona: 033 452 709<br>
    E-mail: jpmdcg&#64;t-com.me
  </p>
  <p><strong>Potrošač</strong>, u smislu ovih Opštih uslova kupovine, može biti fizičko ili pravno lice koje zaključuje pravni posao online kupovine karte za trajektni prevoz na liniji Kamenari – Lepetane odnosno zaključivanje ugovora na daljinu putem elektronske prodavnice (Web Shop)".</p>
  <p><strong>Elektronska prodavnica (Web Shop)</strong>, u smislu ovih Opštih uslova kupovine, je prodavnica na internetu preko koje trgovac nudi potrošaču online kupovinu karte za trajektni prevoz na liniji Kamenari – Lepetane. Preko Web Shop-a trgovac u svojstvu prodavca i potrošač u svojstvu kupca zaključuju ugovor na daljinu, bez istovremenog prisustva prodavca i potrošača upotrebom sredstava komunikacije na daljinu.</p>
  <h3>Opšte odredbe</h3>
  <p>Naručivanje usluge online kupovine karte za trajektni prevoz na liniji Kamenari – Lepetane putem Web Shop-a je regulisano ovim uslovima.</p>
  <p>Uslovi kupovine definišu: Postupak naručivanja, Cijenu, Način plaćanja, Izjavu o konverziji, Zaštitu povjerljivih podataka o transakciji, Završni korak pri kupovini na Web Shop-u,  Povrat sredstava- jednostrani raskid ugovora.</p>
  <p>Kupovinom proizvoda putem Web Shop-a smatra se da je potrošač pročitao i prihvatio Uslove koji se smatraju sastavnim dijelom ugovora na daljinu.</p>
  <p>Potrošač je dužan dati tačne i potpune podatke prilikom kreiranja kupovine.</p>
  <p>Potrošač vrši online plaćanje usluge putem elektronskog obrasca kupovine.</p>
  <p>Javno preduzeće za upravljanje morskim dobrom Crne Gore zadržava pravo izmjene Uslova kupovine, s toga je potrošač dužan da se o Uslovima kupovine informiše svaki put kada pristupa Web Shop-u.</p>
  <p>Odgovornost usljed nepostupanja Potrošača na ovaj način snosi Potrošač.</p>
  <h3>Postupak naručivanja</h3>
  <p>Kupovanje karte putem sajta <a href="http://www.morskodobro.me">www.morskodobro.me</a> je veoma jednostavno.</p>
  <p>Na sajtu u odjeljku “Pretraži red plovidbe i kupi kartu”  odaberite datum, te nakon popunjavanja kliknite na polje “Pretraživanje”, nakon čega će Vam se otvoriti novo polje u kojem ćete imati mogućnost da izvršite uvid u datum, vrstu prevoznog sredstva za koje kupujete kartu, a od kojeg će zavisiti cijena online karte, nakon čega je potrebno kliknuti opciju “Kupi”.</p>
  <p>Nakon toga, otvoriće Vam se novi prozor u kojem će se konstatovati izabrane opcije, to jest datum, te kategorija vozila kojom ćete se ukrcati na trajekt.</p>
  <p>U slučaju da ste pogrešno izabrali neku od opcija, imate mogućnost da klikom na opciju “Preuredi” opet izaberete sve naprijed navedene mogućnost. Ukoliko ste zadovoljni izabranim opcijama, klikom na “Nastavi” dolazite do novog prozora u kojem Vas obavještavamo koliko košta Vaša karta, odnosno koja je cijena online transakcije prilikom kupovine karte za predmetni trajektni prevoz, te će se otvoriti prozor “Podaci o potrošaču” u kojem ćete biti dužni da se ulogujete i unesete: ime i prezime, adresa, poštanski broj, grad, država, e-mail adresa, ponovljena e-mail adresa, kategorija vozila.
  <p>Ukoliko vršite samo kupovinu, bez logovanja, potrebno je unijeti sljedeće podatke: ime i prezime, grad, e-mail adresa, ponovljena e-mail adresa, kategorija vozila.</p>
  <p>Nakon što ste unijeli sve podatke, potrebno je kliknuti na opciju <strong>“Saglasan sam sa svim uslovima kupovine i plaćanja”</strong> i opciju <strong>“Saglasan sam i pristajem da se moji lični podaci obrađuju u ovom postupku”</strong> , te na kraju kliknuti na opciju “Plaćanje”.</p>
  <p>Nakon klika na opciju “Plaćanje”, u novom prozoru će se pojaviti sve do tada izabrane opcije i podaci o kupcu, sa iznosom cijene za plaćanje, biranjem sredstva za plaćanje (kreditne/debitne kartice), nakon čega je potrebno kliknuti na oznaku sredstva plaćanja i unijeti potrebne podatke: broj kartice, podatke o vremenu važenja kartice kao i kontrolni broj utisnut na kartici.</p>
  <p>Klikom na opciju “Izvrši plaćanje” izvršili ste plaćanje online usluge kupovine karte za prevoz trajektom na liniji Kamenari-Lepetane, o čemu ćete ubrzo nakon izvršenog plaćanja, dobiti i email obavještenje kojim se konstatuje da je izvršeno plaćanje, odnosno da je zaključen ugovor na daljinu, sa svim detaljima izvršene transakcije.</p>
  <h3>Cijena</h3>
  <p>Cijena predmetnih usluga određena je Cjenovnikom ********** (potrebno je ovdje staviti link cjenovnika). Cijene su izražene u eurima. U prodajnu cijenu je uračunat PDV.</p>
  <p>Karta kupljena putem Web Shop-a se plaća po cijeni koja je iskazana na Web Shop-u u momentu kupovine karte (cijene su iskazane sa uračunatim PDV-om).</p>
  <p>Trgovac zadržava pravo da promijeni Cjenovnik.</p>
  <h3>Način plaćanja</h3>
  <p>Narudžbu možete platiti u trenutku kupovine na Web Shop-u platnim karticama: Visa i Master Card.</p>
  <h3>Izjava o konverziji</h3>
  <p>Sva plaćanja će biti obavljena u eurima ( € ). Ukoliko se plaćanje obavlja platnim karticama inostranih Banaka izdavalaca, iznos transakcije će biti konvertovan u lokalnu valutu korisnika kartice, shodno važećim uslovima banke izdavaoca kartice. Zbog navedenih konverzija moguće je da dođe do razlike u cijeni koja je naplaćena, u odnosu na cijenu koja je iskazana na Web Shop-u.</p>
  <h3>Zaštita povjerljivih podataka o transakciji</h3>
  <p>Ukoliko posjedujete Visa ili Master Card platnu karticu odobrenu od strane banke izdavaoca za online plaćanje, možete izabrati ovaj način plaćanja. Nakon što potvrdite narudžbu, vršiće se redirekcija na web stranicu banke preko koje vršite plaćanje.</p>
  <p>Prilikom obrade transakcija kreditnim karticama preko elektronske trgovine, Trgovac preusmjerava kupca na stranu banke gdje se vrši bezbjedan unos podataka o kartici od strane Potrošača. Prilikom unošenja podataka o platnoj kartici, povjerljive informacija se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi upotrebom trenutno najsavremenije kriptografske tehnologije. Sigurnost podataka prilikom kupovine, garantuje procesor platnih kartica, pa se tako kompletan proces naplate obavlja na stranicama banke. Niti jednog trenutka podaci o platnoj kartici nijesu dostupni našem sistemu.</p>
  <p>Ukoliko prilikom kreiranja narudžbe Potrošač odabere način plaćanja bankovnim karticama i uspješno izvrši transakciju, sredstva će Potrošaču biti rezervisana/prensena na račun.</p>
  <h3>Završni korak pri kupovini na Web Shop-u</h3>
  <p>Nakon klika na dugme “ Izvrši Plaćanje” dobićete automatsku e-mail potvrdu sledeće sadržine:<br>
    „Poštovani,<br>
    uspješno je izvršena transakcija prema Javnom preduzeću za upravljanje morskim dobrom Crne Gore za kupovinu karte za korišćenje usluge prevoza trajektne linije Kamenari-Lepetane. U nastavku e mail-a dostavljamo Vam račun sa QR kodom i pregled naručene karte.„<br>
    S poštovanjem,<br><br>
    JAVNO PREDUZEĆE ZA UPRAVLJANJE MORSKIM DOBROM CRNE GORE<br>
    Popa Jola Zeca bb Budva<br>
    trajekt&#64;morskodobro.me<br>
    jpmdcg&#64;morskodobro.me<br>
    033/452-709
  </p>
  <p>Momentom slanja potvrdne e mail poruke Javno preduzeće za upravljanje morskim dobrom Crne Gore prihvata Vašu ponudu za zaključenje ugovora. U prilogu ovog e mail-a biće Vam dostavljena i karta za prevoz trajektom sa QR kodom koju je potrebno odštampati i imati kod sebe u trenutku prije ulaska na trajekt, odnosno imati je na ekranu mobilnog telefona u datom momentu.</p>
  <h3>Jednostrani raskid ugovora</h3>
  <p>Potrošač ima pravo da, bez navođenja razloga, jednostrano raskine ugovor zaključen van poslovnih prostorija ili zaključen na daljinu u roku od 14 dana od dana zaključenja ugovora o uslugama.</p>
  <p>U slučaju jednostranog raskida ugovora, u ovom slučaju, imate pravo da Vam novac bude vraćen.</p>
  <p>Protekom roka od 14 dan od datuma zaključenja ugovora, potrošač gubi pravo na jednostrani raskid ugovora.</p>
  <p>Potrošač nema pravo na jednostrani raskid ugovora, odnosno na povrat sredstava, ako je ugovor o uslugama trgovac u potpunosti izvršio, a izvršenje je započeto uz prethodni izričiti pristanak potrošača i njegovu potvrdu da je upoznat sa činjenicom da će izgubiti pravo na jednostrani raskid ugovora.</p>
  <p>Omogućava se potrošaču da jednom kupovinom, odnosno jednom transakcijom izvrši online kupovinu više karata.</p>
  <p>U slučaju iz prethodnog stava, svaka od kupljenih karata iz predmetne transakcije se ima smatrati posebnim zaključenim ugovorom na daljinu u skladu sa čim se i utvrđeni uslovi jednostranog raskida imaju primjenjivati na svaku pojedinačno kupljenu kartu iz predmetne transakcije.</p>
  <p>Obrazac za jednostrani raskid ugovora možete preuzeti ovdje. **** (linkovati ga ovdje)</p>
  <p>Izjava o jednostranom raskidu ugovora proizvodi pravno dejstvo od dana kada je dostavljena trgovcu.</p>
  <p>Potrošač će biti obaviješten putem emaila da je Javno preduzeće za upravljanje morskim dobrom primilo izjavu o jednostranom raskidu ugovora.</p>
  <p>Ukoliko ste se odlučili da odustanete od već kupljene karte, potrebno je da nam izjavu o jednostranom raskidu dostavite na email adresu: <a href="mailto:jpmdcg@t-com.me">jpmdcg&#64;t-com.me</a> ili neposrednom predajom Javnom preduzeću za upravljanje morskim dobrom kako bi dobili potrebne instrukcije vezano za povrat sredstava.
  <p>Povrat sredstva potrošaču koji je plaćanje izvršio platnom karticom, izvršiće se na račun sa kojeg je Javno preduzeće za upravljanje morskim dobrom Crne Gore primilo uplatu, nakon što trgovac utvrdi da karta nije iskorišćena. Uplaćena sredstva će se uplatiti potrošaču najkasnije u roku od 14 (četrnaest) dana od datuma prijema izjave o jednostranom raskidu a konačan rok u kome će sredstva biti vraćena potrošaču zavisi isključivo od politike banke preko koje je transakcija izvršena.
  <p>U slučaju povrata sredstava Javno preduzeće za upravljanje morskim dobrom Crne Gore vrši povrat sredstava u iznosu plaćene usluge.
  <h2>KONTAKTIRAJTE SA NAMA</h2>
  <p>Ukoliko imate pitanje u vezi sa online kupovinom karte, kontaktirajte nas na:</p>
  <p>E-mail <a href="mailto:jpmdcg@t-com.me">jpmdcg&#64;t-com.me</a><br>
    Telefonom na 033/452-709</p>
</ng-template>

<ng-template #ru>
  <h1>[RU] Terms and Conditions</h1>
  <p>Last Revised: December 16, 2013</p>
  <p><a href="">Welcome to www.lorem-ipsum.info.</a> This site is provided as a service to our visitors and may be used for
    informational purposes only. Because the Terms and Conditions contain legal obligations, please read them
    carefully.</p>
  <h2>1. YOUR AGREEMENT</h2>
  <p>By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree
    to these Terms and Conditions, please do not use this site.</p>
  <blockquote>PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these
    Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please
    review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes
    and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of
    these standards for notice of changes. For your information, this page was last updated as of the date at the top of
    these terms and conditions.
  </blockquote>
  <h3>2. PRIVACY</h3>
  <p>Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.</p>
  <h2>3. LINKED SITES</h2>
  <p>This Site may contain links to other independent third-party Web sites ("Linked Sites&#x201D;). These Linked Sites
    are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not
    responsible for and does not endorse the content of such Linked Sites, including any information or materials
    contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with
    these Linked Sites.</p>
  <ul>
    <li><strong>Some Item 1</strong></li>
    <li>Some Item 1</li>
    <li>Some Item 1</li>
  </ul>
  <ol>
    <li><strong>Some Item 1</strong></li>
    <li>Some Item 1</li>
    <li>Some Item 1</li>
  </ol>
  <h2>4. FORWARD LOOKING STATEMENTS</h2>
  <p>All materials reproduced on this site speak as of the original date of publication or filing. The fact that a
    document is available on this site does not mean that the information contained in such document has not been
    modified or superseded by events or by a subsequent document or filing. We have no duty or policy to update any
    information or statements contained on this site and, therefore, such information or statements should not be relied
    upon as being current as of the date you access this site.</p>
  <h2>5. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h2>
  <p>A. THIS SITE MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL ERRORS. WE DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF
    THE MATERIALS OR THE RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION DISPLAYED OR DISTRIBUTED
    THROUGH THE SITE. YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (i) YOUR USE OF THE SITE, INCLUDING ANY RELIANCE ON ANY
    SUCH OPINION, ADVICE, STATEMENT, MEMORANDUM, OR INFORMATION CONTAINED HEREIN, SHALL BE AT YOUR SOLE RISK; (ii) THE
    SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS; (iii) EXCEPT AS EXPRESSLY PROVIDED HEREIN WE DISCLAIM ALL
    WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO IMPLIED WARRANTIES OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT, TITLE AND NON-INFRINGEMENT; (iv) WE MAKE NO
    WARRANTY WITH RESPECT TO THE RESULTS THAT MAY BE OBTAINED FROM THIS SITE, THE PRODUCTS OR SERVICES ADVERTISED OR
    OFFERED OR MERCHANTS INVOLVED; (v) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DONE
    AT YOUR OWN DISCRETION AND RISK; and (vi) YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
    FOR ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</p>
  <p>B. YOU UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE BE
    LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF,
    OR THE INABILITY TO USE, ANY OF OUR SITES OR MATERIALS OR FUNCTIONS ON ANY SUCH SITE, EVEN IF WE HAVE BEEN ADVISED
    OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL
    PURPOSE OF ANY LIMITED REMEDY.</p>
  <h2>6. EXCLUSIONS AND LIMITATIONS</h2>
  <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
    INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, OUR LIABILITY IN SUCH JURISDICTION SHALL BE LIMITED TO THE MAXIMUM
    EXTENT PERMITTED BY LAW.</p>
  <h2>7. OUR PROPRIETARY RIGHTS</h2>
  <p>This Site and all its Contents are intended solely for personal, non-commercial use. Except as expressly provided,
    nothing within the Site shall be construed as conferring any license under our or any third party's intellectual
    property rights, whether by estoppel, implication, waiver, or otherwise. Without limiting the generality of the
    foregoing, you acknowledge and agree that all content available through and used to operate the Site and its
    services is protected by copyright, trademark, patent, or other proprietary rights. You agree not to: (a) modify,
    alter, or deface any of the trademarks, service marks, trade dress (collectively "Trademarks") or other intellectual
    property made available by us in connection with the Site; (b) hold yourself out as in any way sponsored by,
    affiliated with, or endorsed by us, or any of our affiliates or service providers; (c) use any of the Trademarks or
    other content accessible through the Site for any purpose other than the purpose for which we have made it available
    to you; (d) defame or disparage us, our Trademarks, or any aspect of the Site; and (e) adapt, translate, modify,
    decompile, disassemble, or reverse engineer the Site or any software or programs used in connection with it or its
    products and services.</p>
  <p>The framing, mirroring, scraping or data mining of the Site or any of its content in any form and by any method is
    expressly prohibited.</p>
  <h2>8. INDEMNITY</h2>
  <p>By using the Site web sites you agree to indemnify us and affiliated entities (collectively "Indemnities") and hold
    them harmless from any and all claims and expenses, including (without limitation) attorney's fees, arising from
    your use of the Site web sites, your use of the Products and Services, or your submission of ideas and/or related
    materials to us or from any person's use of any ID, membership or password you maintain with any portion of the
    Site, regardless of whether such use is authorized by you.</p>
  <h2>9. COPYRIGHT AND TRADEMARK NOTICE</h2>
  <p>Except our generated dummy copy, which is free to use for private and commercial use, all other text is
    copyrighted. generator.lorem-ipsum.info &#xA9; 2013, all rights reserved</p>
  <h2>10. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS</h2>
  <p>It is our policy to respond expeditiously to claims of intellectual property infringement. We will promptly process
    and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium
    Copyright Act ("DMCA") and other applicable intellectual property laws. Notices of claimed infringement should be
    directed to:</p>
  <p>generator.lorem-ipsum.info</p>
  <p>123 Some Street</p>
  <p>City, City</p>
  <p>Country</p>
  <p>Contact</p>
  <h2>11. PLACE OF PERFORMANCE</h2>
  <p>This Site is controlled, operated and administered by us from our office in Kiev, Ukraine. We make no
    representation that materials at this site are appropriate or available for use at other locations outside of the
    Ukraine and access to them from territories where their contents are illegal is prohibited. If you access this Site
    from a location outside of the Ukraine, you are responsible for compliance with all local laws.</p>
  <h2>12. GENERAL</h2>
  <p>A. If any provision of these Terms and Conditions is held to be invalid or unenforceable, the provision shall be
    removed (or interpreted, if possible, in a manner as to be enforceable), and the remaining provisions shall be
    enforced. Headings are for reference purposes only and in no way define, limit, construe or describe the scope or
    extent of such section. Our failure to act with respect to a breach by you or others does not waive our right to act
    with respect to subsequent or similar breaches. These Terms and Conditions set forth the entire understanding and
    agreement between us with respect to the subject matter contained herein and supersede any other agreement,
    proposals and communications, written or oral, between our representatives and you with respect to the subject
    matter hereof, including any terms and conditions on any of customer's documents or purchase orders.</p>
  <p>B. No Joint Venture, No Derogation of Rights. You agree that no joint venture, partnership, employment, or agency
    relationship exists between you and us as a result of these Terms and Conditions or your use of the Site. Our
    performance of these Terms and Conditions is subject to existing laws and legal process, and nothing contained
    herein is in derogation of our right to comply with governmental, court and law enforcement requests or requirements
    relating to your use of the Site or information provided to or gathered by us with respect to such use.</p>
</ng-template>
