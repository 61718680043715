import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';
import { ProductModel } from '../../core/rest/products/model/product.model';

@Injectable()
export class CheckoutTotalAmountService {
  private productsSubject = new BehaviorSubject<ProductModel[]>([]);
  products$ = this.productsSubject.asObservable();

  get totalAmount$(): Observable<number> {
    return this.products$
      .pipe(
        map(products => {
          if (!products?.length) {
            return 0;
          }
          return products
            .reduce((sum, product) => sum + product.price * product.quantity, 0);
        }),
        distinctUntilChanged(),
      )
  }

  updateProducts(products: ProductModel[]): void {
    this.productsSubject.next([...products]);
  }

  decreaseProductQuantity(product: ProductModel) {
    this.updateProductQuantity(product, product.quantity - 1);
  }

  increaseProductQuantity(product: ProductModel) {
    this.updateProductQuantity(product, product.quantity + 1);
  }

  updateProductQuantity(product: ProductModel, quantity: number) {
    const updatedProducts = this.productsSubject.value
      .map((p) =>
      p.id === product.id ? {...p, quantity: Math.max(0, quantity)} : p
    );
    this.productsSubject.next(updatedProducts);
  }
}
