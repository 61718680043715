import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LogoComponent } from '../../shared/components/ui/logo/logo.component';
import { AuthService } from '../../core/rest/auth/auth.service';
import { CustomerRestService } from '../../core/rest/customer/customer-rest.service';
import { catchError, switchMap, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../core/services/snackbar.service';

@Component({
  selector: 'app-account-activation-failed',
  standalone: true,
  imports: [
    TranslateModule,
    LogoComponent,
  ],
  templateUrl: './account-activation-failed.component.html',
  styleUrl: './account-activation-failed.component.scss'
})
export class AccountActivationFailed {

  translateService = inject(TranslateService);
  authService = inject(AuthService);
  customerRestService = inject(CustomerRestService);
  snackBarService = inject(SnackBarService);

  sendActivationLink(): void {
    this.authService.registerEmail
      .pipe(
        switchMap(email => {
          return this.customerRestService.sendActivationLink(email)
            .pipe(
              catchError((err: HttpErrorResponse) => {
                const message = this.customerRestService.getErrorMessage(err);
                this.snackBarService.showErrorMessage(message);
                return throwError(() => err);
              })
            );
        })
      ).subscribe(() => {
        this.snackBarService.showSuccessMessage(this.translateService.instant('ACCOUNT.REACTIVATION_CONFIRMATION'));
        this.authService.setRegisterMail('');
      });
  }
}
