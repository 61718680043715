import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LogoComponent } from '../../shared/components/ui/logo/logo.component';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-password-set-new-confirmation',
  standalone: true,
  imports: [
    RouterLink,
    TranslateModule,
    LogoComponent,
    NgOptimizedImage,
  ],
  templateUrl: './password-set-new-confirmation.component.html',
  styleUrl: './password-set-new-confirmation.component.scss',
})
export class PasswordSetNewConfirmationComponent {

}
