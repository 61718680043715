<div class="c-password-change">
  <app-dual-zone>
    <form [formGroup]="form">
      <ng-template appDualZoneBody>
        <app-panel [title]="'PASSWORD_CHANGE.TITLE' | translate"
          [collapsible]="false"
          [filterBy]="false">
          <div class="u-form-element-wrapper">
            <mat-label>{{ 'COMMON.FORM_FIELDS.OLD_PASSWORD.LABEL' | translate }} <span class="u-form-element-required-star">*</span></mat-label>
            <mat-form-field class="u-form-field-full" appearance="outline">
              <input matInput [placeholder]="'COMMON.FORM_FIELDS.OLD_PASSWORD.PLACEHOLDER' | translate"
                [type]="hideOld ? 'password' : 'text'" [formControlName]="'oldPassword'" required>
              <button matSuffix mat-icon-button (click)="hideOld = !hideOld" type="button">
                <mat-icon [svgIcon]="hideOld ? 'svg:visibility_off' : 'svg:visibility'"></mat-icon>
              </button>
            </mat-form-field>
            @if (form.get('oldPassword')?.invalid && form.get('oldPassword')?.touched) {
              <mat-error *ngIf="form.get('oldPassword')?.hasError('required')">
                {{ 'COMMON.FORM_FIELDS.EMAIL.REQUIRED_MESSAGE' | translate }}
              </mat-error>
            }
          </div>

          <div class="u-form-element-wrapper">
            <mat-label>{{ 'COMMON.FORM_FIELDS.NEW_PASSWORD.LABEL' | translate }} <span class="u-form-element-required-star">*</span></mat-label>
            <mat-form-field class="u-form-field-full" appearance="outline">
              <input matInput [placeholder]="'COMMON.FORM_FIELDS.NEW_PASSWORD.PLACEHOLDER' | translate"
                [type]="hide ? 'password' : 'text'" [formControlName]="'password'" required>
              <button matSuffix mat-icon-button (click)="hide = !hide" type="button">
                <mat-icon [svgIcon]="hide ? 'svg:visibility_off' : 'svg:visibility'"></mat-icon>
              </button>
            </mat-form-field>
            @if (form.get('password')?.invalid && form.get('password')?.touched) {
              <mat-error *ngIf="form.get('password')?.hasError('pattern')">
                {{ 'COMMON.FORM_FIELDS.PASSWORD.PASSWORD_FORMAT_MESSAGE' | translate }}
              </mat-error>
            }
            @if(form.get('password')?.invalid && form.get('password')?.touched) {
              @if (!getPatternMessage('^(?=.*[A-Z])')) {
                <mat-error>{{'COMMON.FORM_FIELDS.PASSWORD.PASSWORD_FORMAT_UPPERCASE' | translate}}</mat-error>
              }
              @if (!getPatternMessage('(?=.*[a-z])')) {
                <mat-error>{{'COMMON.FORM_FIELDS.PASSWORD.PASSWORD_FORMAT_LOWERCASE' | translate}}</mat-error>
              }
              @if (!getPatternMessage('(.*[0-9].*)')) {
                <mat-error>{{'COMMON.FORM_FIELDS.PASSWORD.PASSWORD_FORMAT_DIGIT' | translate}}</mat-error>
              }
              @if (!getPatternMessage('(?=.*[!@#$%^&*])')) {
                <mat-error>{{'COMMON.FORM_FIELDS.PASSWORD.PASSWORD_FORMAT_SPECIAL_CHAR' | translate}}</mat-error>
              }
              @if (!getPatternMessage('.{8,}')) {
                <mat-error>{{'COMMON.FORM_FIELDS.PASSWORD.PASSWORD_FORMAT_STRENGTH' | translate}}</mat-error>
              }
            }
          </div>

          <div class="u-form-element-wrapper">
            <mat-label>{{ 'COMMON.FORM_FIELDS.CONFIRM_NEW_PASSWORD.LABEL' | translate }}<span class="u-form-element-required-star">*</span></mat-label>
            <mat-form-field class="u-form-field-full" appearance="outline">
              <input matInput [placeholder]="'COMMON.FORM_FIELDS.CONFIRM_NEW_PASSWORD.PLACEHOLDER' | translate"
                [type]="hideRepeat ? 'password' : 'text'" [formControlName]="'rePassword'" required>
              <button matSuffix mat-icon-button (click)="hideRepeat = !hideRepeat" type="button">
                <mat-icon [svgIcon]="hideRepeat ? 'svg:visibility_off' : 'svg:visibility'"></mat-icon>
              </button>
            </mat-form-field>
            @if (form.get('rePassword')?.invalid && form.get('rePassword')?.touched) {
              <mat-error *ngIf="form.get('rePassword')?.hasError('required')">
                {{ 'COMMON.FORM_FIELDS.CONFIRM_NEW_PASSWORD.REQUIRED_MESSAGE' | translate }}
              </mat-error>
            }
            @if (form.invalid && form.get('rePassword')?.touched && form.hasError('passwordsMismatch')) {
              <mat-error> {{ 'COMMON.FORM_FIELDS.CONFIRM_NEW_PASSWORD.PASSWORD_PATTERN_MESSAGE' | translate }}</mat-error>
            }
          </div>
        </app-panel>
      </ng-template>

      <ng-template appDualZoneFooter>
        <app-button
          [label]="'COMMON.SAVE_CHANGES' | translate"
          [isFullWidth]="true"
          [disabled]="!form.valid"
          (click)="save()">
        </app-button>
      </ng-template>
    </form>
  </app-dual-zone>
</div>
