import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { AppConfiguration } from '../../../app-configuration';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { CustomerModel } from "../../models/customer/customer.model";

@Injectable({
  providedIn: 'root'
})
export class CustomerRestService {
  controllerUrl = this.appConfiguration.serverApiUrl + '/customer';

  constructor(private appConfiguration: AppConfiguration,
              private httpClient: HttpClient) { }

  register(customer: CustomerModel): Observable<any> {
    return this.httpClient.post(this.controllerUrl + '/register', customer)
  }

  getAccount():Observable<CustomerModel> {
    return this.httpClient.get<CustomerModel>(this.controllerUrl + '/view');
  }

  saveAccount(customer:CustomerModel):Observable<any> {
    return this.httpClient.patch(this.controllerUrl, customer)
  }

  resetPasswordEmail(email:string) {
    return this.httpClient.post(this.controllerUrl + '/password/reset', {email})
  }

  resetPasswordResetCode(resetCode: string, password: string) {
    const payload = {
      password: password,
      rePassword: password,
      resetCode: resetCode
    }
    return this.httpClient.post(this.controllerUrl + '/password/reset/update?resetCode=' + resetCode, payload)
  }

  login(credentials: {email: string; password: string}): Observable<CustomerModel> {
    return this.httpClient.post<CustomerModel>(this.controllerUrl + '/login', credentials)
  }
  loginWithGoogle(token:string):Observable<any> {
    const body = {token: token};
    return this.httpClient.post(this.controllerUrl + '/google/login', body)
  }

  logout() {
    return this.httpClient.get(this.controllerUrl + '/logout');
  }

  updatePassword(oldPassword: string, password: string): Observable<any> {
    const payload = {
      password: oldPassword,
      newPassword: password,
      reNewPassword: password
    }
    return this.httpClient.post(this.controllerUrl + '/password/update', payload);
  }

  getErrorMessage(err:HttpErrorResponse) {
    let message= `${err.error?.message}.`
    if (err.error?.additionalInfo) {
      message = `${message} ` + Object.values(err.error['additionalInfo']).toString().split(',').join('. ')
    }
    return message
  }

  getLoggedUser(): Observable<{ authenticated: boolean; user: CustomerModel }> {
    return this.httpClient.get<{ authenticated: boolean; user: CustomerModel }>(this.appConfiguration.serverApiUrl + '/session-info');
  }

  sendActivationLink(email: string): Observable<any> {
    return this.httpClient.post(this.controllerUrl + '/resend-verification-code', {email: email});
  }

  verifyCustomer(verificationCode: string): Observable<any> {
    return this.httpClient.get(this.controllerUrl + `/verify`, {params: {token: verificationCode}});
  }
}



