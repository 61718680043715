import { Pipe, PipeTransform } from '@angular/core';
import { ProductModel } from "../../core/rest/products/model/product.model";

@Pipe({
  name: 'sortByPrice',
  standalone: true
})
export class SortByPricePipe implements PipeTransform {
  transform(products: ProductModel[]): ProductModel[] {
    return products
      .sort((a, b) => {
        return a.price - b.price;
    });
  }

}
