import { NgModule } from '@angular/core';
import { ProductsRestService } from './products/products-rest.service';
import { CartRestService } from './cart/cart-rest.service';
import { CustomerRestService} from "./customer/customer-rest.service";

@NgModule({
  providers: [
    ProductsRestService,
    CartRestService,
    CustomerRestService
  ]
})
export class RestModule {
}
