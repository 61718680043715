<div class="c-select-product-item">
  <div class="c-select-product-item__description-wrapper">
    <div class="c-select-product-item__image" *ngFor="let productImage of product.images">
      <img class="c-select-product-item__image-img fixed-size-img" [ngSrc]="productImage" width="auto" height="40" alt="{{ product.name }}">
    </div>
    <div class="c-select-product-item__description" >
      <ng-container *ngIf="product.description">{{ product.description }}</ng-container>
      <ng-container *ngIf="!product.description">{{ product.name }}</ng-container>
    </div>
  </div>
  <ng-container *ngIf="product?.price">
    <div class="c-select-product-item__price">
      <app-price [amount]="product.price"></app-price>
    </div>
  </ng-container>
</div>
