<div class="c-main">
  <header class="c-main__header"
    [ngClass]="[
      headerHasShadow ? 'c-main__header--has-shadow' : '',
    ]">
    <div class="c-main__header-inner">
      <div class="c-main__header-inner-logo">
        <app-logo [size]="'s'" [isLink]="true"></app-logo>
      </div>
      <div class="c-main__header-inner-user-menu">
        <app-user-menu></app-user-menu>
      </div>
      <div class="c-main__header-inner-language-switcher">
        <app-language-switcher></app-language-switcher>
      </div>
    </div>
  </header>
  <main class="c-main__body">
    <div class="c-main__intersection-observer" #mainIntersectionObserverTrigger></div>
    <router-outlet></router-outlet>
  </main>
  <div class="c-main__payment-info">
    <div class="c-main__payment-info-amount">
      <app-total-amount
        label="{{ 'COMMON.TOTAL_AMOUNT' | translate }}"
        [amount]="(totalAmountService.totalAmount$ | async) || 0">
      </app-total-amount>
    </div>
    <div class="c-main__payment-info-actions">
      <app-button
        label="{{ 'COMMON.CONTINUE' | translate }}"
        [disabled]="!(totalAmountService.totalAmount$ | async)"
        [color]="'neutral-inverted'"
        [isFullWidth]="true"
        (clickEvent)="continueToCheckout()">
      </app-button>
    </div>
  </div>
  <footer class="c-main__footer">
    <app-footer-info></app-footer-info>
  </footer>
</div>
