
import { AbstractControl,  ValidationErrors, ValidatorFn } from '@angular/forms';

export const PASSWORD_PATTERN = '^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\\w\\d\\s:])([^\\s]){8,}$'
export const STRING_PATTERN = '^[A-Za-z]+$'

export function matchValidator(matchTo: string,reverse?: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.parent && reverse) {
      const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
      if (c) { c.updateValueAndValidity();}
      return null;
    }
    return !!control.parent && !!control.parent.value && control.value === (control.parent?.controls as any)[matchTo].value
      ? null
      : { matching: true };
  };
}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return control.get('password')?.value === control.get('rePassword')?.value ? null : { passwordsMismatch: true };
};

export const confirmEmailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return control.get('email')?.value === control.get('reEmail')?.value ? null : { emailsMismatch: true };
}
