import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-strike-through',
  standalone: true,
  imports: [],
  templateUrl: './text-strike-through.component.html',
  styleUrl: './text-strike-through.component.scss'
})
export class TextStrikeThroughComponent {
  @Input() label!: string;
}
