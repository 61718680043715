import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TripleZoneBodyDirective } from '../../shared/components/layouts/triple-zone/triple-zone-body.directive';
import { TripleZoneFooterDirective } from '../../shared/components/layouts/triple-zone/triple-zone-footer.directive';
import { TripleZoneHeaderDirective } from '../../shared/components/layouts/triple-zone/triple-zone-header.directive';
import { TripleZoneComponent } from '../../shared/components/layouts/triple-zone/triple-zone.component';
import { ButtonComponent } from '../../shared/components/ui/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CustomerRestService } from "../../core/rest/customer/customer-rest.service";
import { confirmPasswordValidator, matchValidator, PASSWORD_PATTERN } from "../../core/utils/form-inputs-validator";
import { NgIf } from "@angular/common";
import { SnackBarService } from "../../core/services/snackbar.service";
import { catchError, throwError } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { LogoComponent } from '../../shared/components/ui/logo/logo.component';
import { IconComponent } from '../../shared/components/ui/icon/icon.component';

@Component({
  selector: 'app-password-set-new',
  standalone: true,
  imports: [
    ButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    TripleZoneBodyDirective,
    TripleZoneComponent,
    TripleZoneFooterDirective,
    TripleZoneHeaderDirective,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    TranslateModule,
    NgIf,
    ReactiveFormsModule,
    LogoComponent,
    IconComponent,
  ],
  templateUrl: './password-set-new.component.html',
  styleUrl: './password-set-new.component.scss',
})
export class PasswordSetNewComponent implements OnInit{
  hide = true;
  hideRepeat = true;
  form!: FormGroup;
  resetCode!: any;

  constructor(private customerRestService: CustomerRestService,
              private router: Router,
              private route: ActivatedRoute,
              private snackBarService: SnackBarService,) {}

  ngOnInit() {
    this.resetCode = this.route.snapshot.queryParamMap.get('resetCode');
    this.initResetPasswordForm();
  }

  initResetPasswordForm() {
    this.form = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern(PASSWORD_PATTERN)]),
      rePassword: new FormControl('',[Validators.required, matchValidator('password')]),
    }, {validators: confirmPasswordValidator})
  }

  saveResetPassword(){
    const password:string = this.form.get('password')?.value;
    this.customerRestService.resetPasswordResetCode(this.resetCode, password)
      .pipe(
        catchError((err: HttpErrorResponse)=> {
          const message = this.customerRestService.getErrorMessage(err);
          this.snackBarService.showErrorMessage(message);
          return throwError(() => err);
        })
      )
      .subscribe( res => {
        if(res) {
          this.router.navigate(['password/set-new/confirmation']);
        }
      })
  }

  getPatternMessage(pattern?:string) {
    return this.form.get('password')?.value.match(pattern)
  }

}
