import { AsyncPipe, NgClass } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DualZoneBodyDirective } from '../../shared/components/layouts/dual-zone/dual-zone-body.directive';
import { DualZoneFooterDirective } from '../../shared/components/layouts/dual-zone/dual-zone-footer.directive';
import { DualZoneComponent } from '../../shared/components/layouts/dual-zone/dual-zone.component';
import { BadgeComponent } from '../../shared/components/ui/badge/badge.component';
import { ButtonComponent } from '../../shared/components/ui/button/button.component';
import { PanelComponent } from '../../shared/components/ui/panel/panel.component';
import { PriceComponent } from '../../shared/components/ui/price/price.component';
import { TicketComponent } from '../../shared/components/ui/ticket/ticket.component';
import { TotalAmountComponent } from '../../shared/components/ui/total-amount/total-amount.component';
import { FormsModule } from '@angular/forms';
import { CartProductModel } from '../../core/rest/cart/model/cart-product.model';
import { CartModel } from '../../core/rest/cart/model/cart.model';
import { CartRestService } from '../../core/rest/cart/cart-rest.service';
import { catchError, throwError } from 'rxjs';
import { CustomerRestService } from '../../core/rest/customer/customer-rest.service';
import { SnackBarService } from '../../core/services/snackbar.service';
import { CheckoutTotalAmountService } from '../checkout/checkout-total-amount.service';
import { ProductModel } from '../../core/rest/products/model/product.model';
import {LangCodesEnum, TranslationService} from "../../core/services/translation.service";
import {AppConfiguration} from "../../app-configuration";
import { ProductsRestService } from '../../core/rest/products/products-rest.service';

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [
    RouterLink,
    DualZoneComponent,
    DualZoneBodyDirective,
    DualZoneFooterDirective,
    PanelComponent,
    TicketComponent,
    AsyncPipe,
    ButtonComponent,
    MatCheckboxModule,
    BadgeComponent,
    PriceComponent,
    TotalAmountComponent,
    TranslateModule,
    FormsModule,
    NgClass,
  ],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
})
export class CartComponent implements OnInit {
  cart: CartProductModel[] = [];
  termsChecked = false;
  dayToday = new Date();
  checkoutTotalAmountService = inject(CheckoutTotalAmountService);
  customerRestService = inject(CustomerRestService);
  cartRestService = inject(CartRestService);
  snackBarService = inject(SnackBarService);
  translateService = inject(TranslateService);
  translationService = inject(TranslationService);
  private appConfiguration: AppConfiguration = inject(AppConfiguration);
  router = inject(Router);
  products: ProductModel[] = [];
  LangCodesEnum = LangCodesEnum;
  productsRestService = inject(ProductsRestService);
  ticketValidityPeriod$ = this.productsRestService.getTicketValidityPeriod();

  ngOnInit(): void {

    this.cartRestService.getCart()
      .pipe(catchError((err) => {
        const message = this.customerRestService.getErrorMessage(err);
        this.snackBarService.showErrorMessage(message);
        return throwError(() => err);
      }))
      .subscribe((response: CartModel) => {
        this.cart = [];

        response.items.forEach(item => {
          for (let i = 0; i < item.quantity; i++) {
            this.cart = [...this.cart, item];
          }
        });

        const productItems = this.cartToProduct(response.items);
        this.checkoutTotalAmountService.updateProducts(productItems);
      });
  }

  cartToProduct(items: CartProductModel[]): ProductModel[] {
    return items.map(item => {
      const {idProduct, name, price, description, quantity, images} = item;
      return {id: idProduct, name, price, description, images, quantity};
    }) as ProductModel[];
  }

  onDeleteCartItemEvent(cartItem: CartProductModel): void {
    const removedDuplicates = this.cart.filter((value, index, self) => self.indexOf(value) === index);

    const cartItemIndex = this.cart.findIndex(item => item === cartItem);

    removedDuplicates
      .map(cartProduct => {
        if (cartProduct.idProduct === cartItem.idProduct) {
          cartProduct.quantity--;
          this.cart.splice(cartItemIndex, 1);
          const {idProduct, quantity, description, images, price, name} = cartProduct;
          this.checkoutTotalAmountService.updateProductQuantity({
            id: idProduct,
            name: name as string,
            price: price as number,
            quantity: quantity,
            description: description as string,
            images: images as []
          }, quantity);
        }
      })

    const toCartItems = removedDuplicates.map(item => {
      const {idProduct, quantity} = item;
      return {idProduct, quantity} as CartProductModel;
    });

    const newCart = {
      items: toCartItems
    }
    this.cartRestService.create(newCart)
      .pipe(catchError((err) => {
        const message = this.customerRestService.getErrorMessage(err);
        this.snackBarService.showErrorMessage(message);
        return throwError(() => err);
      }))
      .subscribe(() => {
        this.snackBarService.showSuccessMessage(this.translateService.instant('CART.REMOVE_FROM_CART_SUCCESSFULLY'));
      });
  }

  onPayClick(): void {
    this.router.navigate(['payment/info']);
  }

  onBackClick(): void {
    this.router.navigate(['checkout']);
  }

  get appConfig() {
    return this.appConfiguration
  }
}
