<div class="c-payment-info">
  <app-dual-zone>
    <ng-template appDualZoneBody>
      <p class="c-payment-info__heading-text" *ngIf="!loggedUser">{{ 'PAYMENT_INFO.INTRO' | translate }} <strong>{{ 'PAYMENT_INFO.GUEST' | translate }}</strong></p>
      <p class="c-payment-info__heading-text" *ngIf="loggedUser">{{ 'PAYMENT_INFO.INTRO_GUEST' | translate }}</p>

      <form [formGroup]="form">
        <div class="u-form-element-wrapper">
          <mat-label>{{ 'COMMON.FORM_FIELDS.FIRST_NAME.LABEL' | translate }} <span
            class="u-form-element-required-star">*</span></mat-label>
          <mat-form-field class="u-form-field-full" appearance="outline">
            <input type="text" formControlName="firstName" matInput
              placeholder="{{ 'COMMON.FORM_FIELDS.FIRST_NAME.PLACEHOLDER' | translate }}">
          </mat-form-field>
          @if (form.get('firstName')?.invalid && form.get('firstName')?.touched) {
            <mat-error *ngIf="form.get('firstName')?.hasError('required')">
              {{ 'COMMON.FORM_FIELDS.FIRST_NAME.REQUIRED_MESSAGE' | translate }}
            </mat-error>
          }
        </div>

        <div class="u-form-element-wrapper">
          <mat-label>{{ 'COMMON.FORM_FIELDS.LAST_NAME.LABEL' | translate }} <span
            class="u-form-element-required-star">*</span></mat-label>
          <mat-form-field class="u-form-field-full" appearance="outline">
            <input type="text" formControlName="lastName" matInput
              placeholder="{{ 'COMMON.FORM_FIELDS.LAST_NAME.PLACEHOLDER' | translate }}">
          </mat-form-field>
          @if (form.get('lastName')?.invalid && form.get('lastName')?.touched) {
            <mat-error *ngIf="form.get('lastName')?.hasError('required')">
              {{ 'COMMON.FORM_FIELDS.LAST_NAME.REQUIRED_MESSAGE' | translate }}
            </mat-error>
          }
        </div>

        <div class="u-form-element-wrapper">
          <mat-label>{{ 'COMMON.FORM_FIELDS.EMAIL.LABEL' | translate }} <span class="u-form-element-required-star">*</span></mat-label>
          <mat-form-field class="u-form-field-full" appearance="outline">
            <input id="email" matInput placeholder="{{ 'COMMON.FORM_FIELDS.EMAIL.PLACEHOLDER' | translate }}" required [formControlName]="'email'">
          </mat-form-field>
          @if (form.get('email')?.invalid && form.get('email')?.touched) {
            <mat-error *ngIf="form.get('email')?.hasError('required')">
              {{ 'COMMON.FORM_FIELDS.EMAIL.REQUIRED_MESSAGE' | translate }}
            </mat-error>
            @if (!getEmailPattern()) {
              <mat-error>{{'COMMON.FORM_FIELDS.EMAIL.EMAIL_FORMAT_MESSAGE' | translate}}</mat-error>
            }
          }
        </div>

        <div class="u-form-element-wrapper">
          <mat-label>{{ 'COMMON.FORM_FIELDS.EMAIL.CONFIRM_EMAIL' | translate }} <span class="u-form-element-required-star">*</span></mat-label>
          <mat-form-field class="u-form-field-full" appearance="outline">
            <input id="reEmail" matInput placeholder="{{ 'COMMON.FORM_FIELDS.EMAIL.PLACEHOLDER' | translate }}" required [formControlName]="'reEmail'">
          </mat-form-field>
          @if (form.get('reEmail')?.invalid && form.get('reEmail')?.touched) {
            <mat-error *ngIf="form.get('reEmail')?.hasError('required')">
              {{ 'COMMON.FORM_FIELDS.EMAIL.REQUIRED_MESSAGE' | translate }}
            </mat-error>
          }
          @if (form.get('reEmail')?.touched && form.hasError('emailsMismatch')) {
            <mat-error> {{ 'COMMON.FORM_FIELDS.EMAIL.EMAIL_PATTERN_MESSAGE' | translate }}</mat-error>
          }
        </div>

        <div class="u-form-element-wrapper">
          <app-phone-field
            [label]="'COMMON.FORM_FIELDS.PHONE.LABEL' | translate"
            [placeholder]="'COMMON.FORM_FIELDS.PHONE.PLACEHOLDER' | translate"
            [isFullWide]="true"
            [formControlName]="'phone'">
          </app-phone-field>
        </div>

        <div class="u-form-element-wrapper c-payment-info__buttons">
          <app-button label="{{ 'PAYMENT_INFO.GO_TO_PAYMENT' | translate }}"
            [isFullWidth]="true"
            [disabled]="!form.valid"
            (clickEvent)="onPayClick()">
          </app-button>
          <app-button label="{{ 'CART.BACK' | translate }}"
            [ngClass]="'c-cart__actions-back'"
            [color]="'secondary'"
            [style]="'flat'"
            [isFullWidth]="true"
            (clickEvent)="onBackClick()">
          </app-button>
        </div>
      </form>
    </ng-template>
    <ng-template appDualZoneFooter *ngIf="!loggedUser">
      <p class="c-payment-info__or-text"><strong>{{ 'PAYMENT_INFO.OR' | translate }}</strong></p>
      <div>
        <p class="u-text-center">{{ 'PAYMENT_INFO.HAVE_ACCOUNT' | translate }} <span class="u-link" [routerLink]="'/login'">{{ 'COMMON.LOGIN' | translate }}</span></p>
      </div>
      <div>
        <app-button label="{{ 'COMMON.REGISTER' | translate }}"
          [isFullWidth]="true"
          [color]="'neutral-inverted'"
          [style]="'flat'"
          [routerLink]="'/register'">
        </app-button>
      </div>
    </ng-template>
  </app-dual-zone>
</div>
