import { AsyncPipe, NgClass, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { PriceComponent } from '../price/price.component';
import { TextWithLabelComponent } from '../text-with-label/text-with-label.component';
import { CartProductModel } from '../../../../core/rest/cart/model/cart-product.model';
import { IconComponent } from '../icon/icon.component';
import { TransformDatePipe } from '../../../pipes/transform-date.pipe';

@Component({
  selector: 'app-ticket',
  standalone: true,
  imports: [
    NgClass,
    TextWithLabelComponent,
    MatCheckboxModule,
    PriceComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    TranslateModule,
    NgIf,
    NgOptimizedImage,
    NgForOf,
    IconComponent,
    TransformDatePipe,
    AsyncPipe,
  ],
  templateUrl: './ticket.component.html',
  styleUrl: './ticket.component.scss'
})
export class TicketComponent {
  @Input() cartItem!: CartProductModel;
  @Input() removable = false;
  @Input() disabled = false;
  @Input() ticketPurchaseDate!: Date;
  @Input() validTo!: string | undefined;
  @Input() validFrom!: Date | string | undefined;
  @Output() deleteCartItemEvent = new EventEmitter<CartProductModel>();

  deleteCartItem(cartItem: CartProductModel): void {
    this.deleteCartItemEvent.emit(cartItem);
  }
}
