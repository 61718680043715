import { Component, Input } from '@angular/core';
import { Lang } from '../../../../../core/services/translation.service';

@Component({
  selector: 'app-language-switcher-item',
  standalone: true,
  imports: [],
  templateUrl: './language-switcher-item.component.html',
  styleUrl: './language-switcher-item.component.scss'
})
export class LanguageSwitcherItemComponent {
  @Input() lang!: Lang;
}
