import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appTripleZoneBody]',
  standalone: true,
})
export class TripleZoneBodyDirective {

  constructor(public tplRef: TemplateRef<any>) { }

}
