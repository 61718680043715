<div class="c-triple-zone"
  [ngClass]="[
    !!headerDirective && !bodyDirective && !footerDirective ? 'c-triple-zone--has-header' : '',
    !!bodyDirective && !headerDirective && !footerDirective ? 'c-triple-zone--has-body' : '',
    !!footerDirective && !headerDirective && !bodyDirective ? 'c-triple-zone--has-footer' : '',
    !!headerDirective && !!bodyDirective && !bodyDirective ? 'c-triple-zone--has-header-and-body' : '',
    !!headerDirective && !bodyDirective && !!footerDirective ? 'c-triple-zone--has-header-and-footer' : '',
    !headerDirective && !!bodyDirective && !!footerDirective ? 'c-triple-zone--has-body-and-footer' : '',
    !!headerDirective && !!bodyDirective && !!footerDirective ? 'c-triple-zone--has-header-and-body-and-footer' : '',
  ]">
  @if (!!headerDirective) {
    <div class="c-triple-zone__header">
      <ng-container [ngTemplateOutlet]="headerDirective.tplRef"></ng-container>
    </div>
  }
  @if (!!bodyDirective) {
    <div class="c-triple-zone__body">
      <ng-container [ngTemplateOutlet]="bodyDirective.tplRef"></ng-container>
    </div>
  }
  @if (!!footerDirective) {
    <div class="c-triple-zone__footer">
      <ng-container [ngTemplateOutlet]="footerDirective.tplRef"></ng-container>
    </div>
  }
</div>
