import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DualZoneBodyDirective } from '../../shared/components/layouts/dual-zone/dual-zone-body.directive';
import { DualZoneFooterDirective } from '../../shared/components/layouts/dual-zone/dual-zone-footer.directive';
import { DualZoneComponent } from '../../shared/components/layouts/dual-zone/dual-zone.component';
import {
  TemplateContainerComponent,
} from '../../shared/components/layouts/template-container/template-container.component';
import { ButtonComponent } from '../../shared/components/ui/button/button.component';
import { PanelComponent } from '../../shared/components/ui/panel/panel.component';
import { TicketComponent } from '../../shared/components/ui/ticket/ticket.component';
import { TranslateModule } from '@ngx-translate/core';
import { CartRestService } from '../../core/rest/cart/cart-rest.service';
import { CartProductModel } from '../../core/rest/cart/model/cart-product.model';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomerRestService } from '../../core/rest/customer/customer-rest.service';
import { SnackBarService } from '../../core/services/snackbar.service';

@Component({
  selector: 'app-tickets',
  standalone: true,
  imports: [
    RouterLink,
    TicketComponent,
    AsyncPipe,
    DualZoneComponent,
    DualZoneBodyDirective,
    DualZoneFooterDirective,
    PanelComponent,
    ButtonComponent,
    TemplateContainerComponent,
    TranslateModule,
  ],
  templateUrl: './tickets.component.html',
  styleUrl: './tickets.component.scss',
})
export class TicketsComponent implements OnInit {
  tickets: CartProductModel[] = [];

  constructor(private cartRestService: CartRestService,
              private customerRestService: CustomerRestService,
              private snackBarService: SnackBarService) {
  }

  ngOnInit(): void {
    this.cartRestService.getCartHistory('date-desc')
      .pipe(
        catchError((err: HttpErrorResponse) => {
          const message = this.customerRestService.getErrorMessage(err);
          this.snackBarService.showErrorMessage(message);
          return throwError(() => err);
        })
      )
      .subscribe((response: CartProductModel[]) => {
        if (response) {
          this.tickets = response;
        }
      });
  }

  onSortTicketEvent(sortType: string): void {
    this.cartRestService.getCartHistory(sortType)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          const message = this.customerRestService.getErrorMessage(err);
          this.snackBarService.showErrorMessage(message);
          return throwError(() => err);
        })
      )
      .subscribe((response: CartProductModel[]) => {
        if (response) {
          this.tickets = response;
        }
      });
  }
}
