import { AsyncPipe, JsonPipe, NgClass, NgIf } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, throwError } from 'rxjs';
import { ProductModel } from '../../core/rest/products/model/product.model';
import {
  TemplateContainerComponent,
} from '../../shared/components/layouts/template-container/template-container.component';
import { ButtonComponent } from '../../shared/components/ui/button/button.component';
import {
  SelectProductItemComponent
} from '../../shared/components/ui/select-product-item/select-product-item.component';
import {
  TextStrikeThroughComponent,
} from '../../shared/components/ui/text-strike-through/text-strike-through.component';
import { CustomerModel } from "../../core/models/customer/customer.model";
import { CustomerRestService } from "../../core/rest/customer/customer-rest.service";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { PASSWORD_PATTERN, confirmPasswordValidator } from "../../core/utils/form-inputs-validator";
import { ProductsRestService } from "../../core/rest/products/products-rest.service";
import { AuthService } from "../../core/rest/auth/auth.service";
import { SnackBarService } from "../../core/services/snackbar.service";
import { HttpErrorResponse } from "@angular/common/http";
import { EMAIL_PATTERN } from '../../core/utils/form-patterns';
import { SortByPricePipe } from "../../shared/pipes/sort-by-price.pipe";
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { PhoneFieldComponent } from '../../shared/components/ui/phone-field/phone-field.component';
import {LangCodesEnum, TranslationService} from "../../core/services/translation.service";
import {AppConfiguration} from "../../app-configuration";

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    RouterLink,
    TemplateContainerComponent,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    ButtonComponent,
    TextStrikeThroughComponent,
    MatCheckboxModule,
    AsyncPipe,
    SelectProductItemComponent,
    TranslateModule,
    ReactiveFormsModule,
    JsonPipe,
    NgClass,
    NgIf,
    SortByPricePipe,
    NgxMatIntlTelInputComponent,
    PhoneFieldComponent,
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})

export class RegisterComponent implements OnInit, AfterViewInit {
  hide: boolean = true;
  hideRepeat: boolean = true;

  registerForm!: FormGroup;
  products: ProductModel[] = [];
  LangCodesEnum = LangCodesEnum;

  constructor(private customerRestService: CustomerRestService,
              private productsRestService: ProductsRestService,
              private router: Router,
              private authService: AuthService,
              private snackBarService: SnackBarService,
              public translationService: TranslationService,
              private appConfiguration: AppConfiguration) {
  }

  ngOnInit() {
    this.initRegisterForm();
    this.productsRestService.getProducts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          const message = this.customerRestService.getErrorMessage(err);
          this.snackBarService.showErrorMessage(message);
          return throwError(() => err);
        })
      )
      .subscribe((products) => {
        this.products = products;
      });
  }

  initRegisterForm() {
    this.registerForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
      password: new FormControl('', [Validators.required, Validators.pattern(PASSWORD_PATTERN)]),
      rePassword: new FormControl('', [Validators.required]),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      termsOfUse: new FormControl(false, Validators.requiredTrue),
      privacyPolicy: new FormControl(false, Validators.requiredTrue),
      idVehicle: new FormControl(null)
    }, {validators: confirmPasswordValidator})
  }

  register() {
    if (!this.registerForm.valid) return;
    const customer: CustomerModel = this.registerForm.value;
    this.customerRestService.register(customer)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          const message = this.customerRestService.getErrorMessage(err);
          this.snackBarService.showErrorMessage(message);
          return throwError(() => err);
        })
      )
      .subscribe(() => {
        this.registerForm.reset();
        this.authService.setRegisterMail(customer.email);
        this.router.navigate(['register/confirmation'])
      });
  }

  ngAfterViewInit() {
    const googleBtnRegister = document.getElementById("googleBtnRegister")
    this.authService.googleAuthInit(googleBtnRegister)
  }

  getPatternMessage(pattern?: string) {
    return this.registerForm.get('password')?.value.match(pattern)
  }

  getEmailPattern() {
    return this.registerForm.get('email')?.value.match(EMAIL_PATTERN);
  }

  get appConfig() {
    return this.appConfiguration
  }
}
