<div class="c-panel">
  @if (!!title || filterBy) {
    <div class="c-panel__header"
      [ngClass]="[
        filterBy ? 'c-panel__header--has-actions' : '',
      ]">
      <div class="c-panel__header-title">
        {{ title }}
      </div>

      @if (collapsible || filterBy) {
        <div class="c-panel__header-actions">
          @if (filterBy) {
            <app-button
              [color]="'secondary'"
              [size]="'s'"
              [style]="'naked'"
              [icon]="isSortDescending ? 'north' : 'south'"
              (clickEvent)="sort()">
            </app-button>
          }

          @if (collapsible) {
            <app-button
              [color]="'secondary'"
              [size]="'s'"
              [style]="'naked'"
              [icon]="expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
              (clickEvent)="toggle()">
            </app-button>
          }
        </div>
      }
    </div>
  }

  @if (expanded) {
    <div class="c-panel__body">
      <ng-content></ng-content>
    </div>
  }
</div>
