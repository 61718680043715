import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from "../../core/rest/auth/auth.service";
import {catchError, map, Observable, of, tap} from "rxjs";
import {CustomerRestService} from "../../core/rest/customer/customer-rest.service";

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedUserCanActivateGuard implements CanActivate {

  constructor(private authService: AuthService,
              private customerRestService:CustomerRestService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.customerRestService.getAccount()
      .pipe(
        tap(user=> this.authService.user.next(user)),
        map(user => !!user)
      )
  }
}
