<div class="c-ticket"
  [ngClass]="[
    removable ? 'c-ticket--has-sidebar' : '',
    disabled ? 'c-ticket--is-disabled' : '',
  ]">
  <div class="c-ticket__main">
    <div class="c-ticket__header">
      <div class="c-ticket__header-direction-wrapper">
        <div class="c-ticket__header-direction">
          <app-text-with-label [label]="'TICKET.DIRECTION' | translate">Kamenari-Lepetane</app-text-with-label>
        </div>
      </div>
      <div class="c-ticket__header-dates">
        <div class="c-ticket__header-date-start">
          <app-text-with-label [label]="'TICKET.OUTBOUND' | translate">
            {{ ticketPurchaseDate | transformDate }}
          </app-text-with-label>
        </div>
      </div>
      <div class="c-ticket__header-season-info">
        {{ 'TICKET.SEASON_INFO' | translate }} {{ validFrom | transformDate }} - {{ validTo | transformDate }}
      </div>
      <div class="c-ticket__header-type-price">
        <div class="c-ticket__header-price">
          <app-text-with-label [label]="'TICKET.PRICE' | translate">
            <app-price
              [color]="'secondary'"
              [isInlineElement]="true"
              [amount]="cartItem.price ? cartItem.price : 0">
            </app-price>
          </app-text-with-label>
        </div>
      </div>
    </div>
    <div class="c-ticket__body">
      <ng-container *ngIf="cartItem.images?.length">
        <div class="c-ticket__body-image" *ngFor="let productImage of cartItem.images">
          <img class="c-ticket__body-image-img" [ngSrc]="productImage" alt="{{cartItem.name}}" width="auto" height="40">
        </div>
      </ng-container>
      <ng-container *ngIf="cartItem.image">
        <div class="c-ticket__body-image">
          <img class="c-ticket__body-image-img" [ngSrc]="cartItem.image" alt="{{cartItem.name}}" width="auto" height="40">
        </div>
      </ng-container>
      <div class="c-ticket__body-description" *ngIf="cartItem.description">{{ cartItem.description }}</div>
      <div class="c-ticket__body-description" *ngIf="!cartItem.description">{{ cartItem.name }}</div>
    </div>
  </div>

  @if (removable) {
    <div class="c-ticket__sidebar">
      <button class="c-ticket__sidebar-action" title="{{ 'COMMON.DELETE' | translate }}" (click)="deleteCartItem(cartItem)">
        <span class="c-ticket__sidebar-action-icon">
          <app-icon [name]="'delete'" [size]="'xl'"></app-icon></span>
      </button>
    </div>
  }
</div>
