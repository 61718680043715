import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from "../../core/rest/auth/auth.service";
import { CustomerRestService } from "../../core/rest/customer/customer-rest.service";
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckForUserGuard implements CanActivate {

  constructor(private authService: AuthService,
              private customerRestService: CustomerRestService) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.customerRestService.getLoggedUser()
      .pipe(
        map(user => {
          if (user.authenticated) {
            this.authService.user.next(user.user);
          }
          return true;
        })
      );
  }
}
