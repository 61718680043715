export class AppConfiguration {
  serverApiUrl: string = '';
  googleClientId: string = '';
  ReturnURL: string = '';
  ReturnErrorURL: string = '';
  CancelURL: string = '';
  termsMe: string = '';
  termsEn: string = '';
  privacyMe: string = '';
  privacyEn: string = '';
}
