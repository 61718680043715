import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LogoComponent } from '../../shared/components/ui/logo/logo.component';

@Component({
  selector: 'app-password-reset-confirmation',
  standalone: true,
  imports: [
    RouterLink,
    TranslateModule,
    LogoComponent,
  ],
  templateUrl: './password-reset-confirmation.component.html',
  styleUrl: './password-reset-confirmation.component.scss',
})
export class PasswordResetConfirmationComponent {

}
