import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appTripleZoneFooter]',
  standalone: true,
})
export class TripleZoneFooterDirective {

  constructor(public tplRef: TemplateRef<any>) { }

}
