import { Component } from '@angular/core';
import {LogoComponent} from "../../ui/logo/logo.component";
import {AsyncPipe, NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";
import {LangCodesEnum, TranslationService} from "../../../../core/services/translation.service";
import {AppConfiguration} from "../../../../app-configuration";

@Component({
  selector: 'app-footer-info',
  standalone: true,
  imports: [
    LogoComponent,
    NgOptimizedImage,
    TranslateModule,
    RouterLink,
    AsyncPipe
  ],
  templateUrl: './footer-info.component.html',
  styleUrl: './footer-info.component.scss'
})
export class FooterInfoComponent {

  LangCodesEnum = LangCodesEnum;
  constructor(public translationService: TranslationService, private appConfiguration: AppConfiguration) {}

  get appConfig() {
    return this.appConfiguration
  }
}
