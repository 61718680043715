import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformDate',
  standalone: true
})
export class TransformDatePipe implements PipeTransform {
  transform(validityDate: string | Date | undefined): string {
    if (validityDate instanceof Date) {
      return this.formatDate(validityDate);
    } else if (typeof validityDate === 'string') {
      return this.formatDate(new Date(validityDate));
    } else {
      return '';
    }
  }

  private formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
}
