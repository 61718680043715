<div class="c-cart">
  <app-dual-zone>
    <ng-template appDualZoneBody>
      <app-panel title="{{ 'CART.PAYMENT' | translate }}"
        [collapsible]="false"
        [filterBy]="false">
        <div class="c-cart__items">
          @for (cartItem of cart; track cartItem.idProduct; let i = $index) {
            <app-ticket
              [ticketPurchaseDate]="dayToday"
              [validFrom]="dayToday"
              [validTo]="(ticketValidityPeriod$ | async)?.purchasedTicketsValidTo"
              [cartItem]="cartItem"
              [removable]="true"
              (deleteCartItemEvent)="onDeleteCartItemEvent($event)">
            </app-ticket>
          } @empty {
            <p class="c-cart__items-empty">{{ 'CART.NO_TICKETS' | translate }}</p>
          } @if (cart.length > 0) {
            <p class="c-cart__items-footer">{{ 'CART.INFO_SENT' | translate }}</p>
          }
        </div>
      </app-panel>
    </ng-template>
    <ng-template appDualZoneFooter>
      <div class="c-cart__total">
        <app-total-amount label="{{ 'COMMON.TOTAL_AMOUNT' | translate }}"
          [amount]="(checkoutTotalAmountService.totalAmount$ | async) || 0">
        </app-total-amount>
      </div>
      <div class="c-cart__conditions">
        <div>
          <mat-checkbox [(ngModel)]="termsChecked" [required]="true">{{ 'CART.TERMS.LABEL' | translate }}
            <a class="u-link" target="_blank"
               [href]="(this.translationService.selectedLanguage$ | async)?.code === LangCodesEnum.MN ? appConfig.termsMe : appConfig.termsEn">
              {{ 'CART.TERMS.LINK_TEXT' | translate }}
            </a></mat-checkbox>
        </div>
      </div>
      <div class="c-cart__actions">
        <app-button label="{{ 'CART.BACK' | translate }}"
          [ngClass]="'c-cart__actions-back'"
          [color]="'secondary'"
          [style]="'flat'"
          [isFullWidth]="true"
          (clickEvent)="onBackClick()">
        </app-button>
        <app-button label="{{ 'CART.PAY' | translate }}"
          [ngClass]="'c-cart__actions-pay'"
          [color]="'primary'"
          [style]="'flat'"
          [isFullWidth]="true"
          [disabled]="!termsChecked || !cart.length"
          (clickEvent)="onPayClick()">
        </app-button>
      </div>
    </ng-template>
  </app-dual-zone>
</div>
