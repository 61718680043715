<div class="c-checkout">
  <div class="c-checkout__intersection-observer" #checkoutIntersectionObserverTrigger></div>
  <form class="c-checkout__form" [formGroup]="form">
    <div class="c-checkout__header">
      <p class="c-checkout__title">{{ 'CHECKOUT.TITLE' | translate }}</p>
      <p class="c-checkout__subtitle">{{ 'CHECKOUT.SUBTITLE' | translate }}</p>
      <div class="c-checkout__date">
        <div class="c-checkout__date-start">
          <mat-label>{{ 'CHECKOUT.OUTBOUND.LABEL' | translate }}</mat-label>
          <mat-form-field appearance="outline" class="u-form-field-full">
            <input
              matInput
              [matDatepicker]="pickerStart"
              [readonly]="true"
              (click)="pickerStart.open()"
              [min]="dayToday"
              [attr.placeholder]="'CHECKOUT.OUTBOUND.PLACEHOLDER' | translate"
              [formControlName]="'departureDate'"
              required
              [disabled]="true"
              [value]="form.controls['departureDate'].value">
            <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <mat-datepicker touchUi #pickerStart></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="c-checkout__date-ticket-validity" *ngIf="ticketValidityPeriod$ | async as ticketValidityPeriod">
          *{{ 'CHECKOUT.OUTBOUND.TICKET_VALIDITY_PERIOD' | translate }} {{ dayToday | transformDate }} - {{ ticketValidityPeriod.purchasedTicketsValidTo | transformDate }}
        </div>
      </div>
    </div>
    <div class="c-checkout__body">
      <section class="c-checkout__products">
        @for (product of checkoutTotalAmountService.products$ | async; track product.id) {
          <app-product-item
            [product]="product">
          </app-product-item>
        }
      </section>
    </div>
  </form>
</div>
