<article class="c-product-item__wrapper">
  <div class="c-product-item"
    [ngClass]="[
      isExpanded ? 'c-product-item--expanded' : '',
    ]">
    <div class="c-product-item__image" *ngFor="let productImage of product.images">
      <img class="c-product-item__image-img fixed-size-img" [ngSrc]="productImage" width="auto" height="30"
        alt="{{ product.name }}">
    </div>

    <div class="c-product-item__header">
      <div class="c-product-item__info" (click)="toggle()">
        <div class="c-product-item__description">
          <div class="c-product-item__description-text">
            {{ product.description ? product.description : product.name }}
          </div>
        </div>
        <div class="c-product-item__actions">
          @if (!isExpanded) {
            <app-button
              [color]="'neutral-inverted'"
              [style]="'naked'"
              [icon]="'expand_more'"
              [size]="'m'">
            </app-button>
          }
          @if (isExpanded) {
            <app-button
              [color]="'neutral-inverted'"
              [style]="'naked'"
              [icon]="'expand_less'"
              [size]="'m'">
            </app-button>
          }
        </div>
        <div class="c-product-item__price">
          <app-price [amount]="product.price"></app-price>
        </div>
      </div>
      <div class="c-product-item__quantity">
        <div class="c-product-item__quantity-decrease">
          <app-button
            [color]="'neutral-inverted'"
            [style]="'naked'"
            [icon]="'remove'"
            [size]="'m'"
            [disabled]="product.quantity <= 0"
            (clickEvent)="decreaseProductQuantity(product)">
          </app-button>
        </div>
        <div class="c-product-item__quantity-value">
          <mat-form-field appearance="outline"
            class="c-product-item__quantity-value-mat-mdc-form-field u-form-field-full">
            <input matInput maxlength="2" type="text" inputmode="numeric" [value]="product.quantity"
              (input)="onInputChange(product, $event)">
          </mat-form-field>
        </div>
        <div class="c-product-item__quantity-increase">
          <app-button
            [color]="'neutral-inverted'"
            [style]="'naked'"
            [icon]="'add'"
            [size]="'m'"
            (clickEvent)="increaseProductQuantity(product)">
          </app-button>
        </div>
      </div>
    </div>

    @if (isExpanded) {
      <div class="c-product-item__body">
        {{ product.description ? product.description : product.name }}
      </div>
    }
  </div>
</article>
