import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppConfiguration } from './app/app-configuration';


fetch('./assets/env.json')
  .then((response) => response.json())
  .then((config: AppConfiguration) => {
    if (environment.production) {
      enableProdMode();
    }

    bootstrapApplication(AppComponent, appConfig(config))
      .catch((err) => console.error(err));
  })
