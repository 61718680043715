import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import {FooterInfoComponent} from "../footer-info/footer-info.component";

@Component({
  selector: 'app-template-container',
  standalone: true,
  imports: [
    NgClass,
    FooterInfoComponent,
  ],
  templateUrl: './template-container.component.html',
  styleUrl: './template-container.component.scss'
})
export class TemplateContainerComponent {
  @Input() padding: 'm' = 'm';
  @Input() fullHeight = false;
}
