import { Component, Input } from '@angular/core';
import { ProductModel } from '../../../../core/rest/products/model/product.model';
import { TranslateModule } from '@ngx-translate/core';
import { NgForOf, NgIf, NgOptimizedImage } from "@angular/common";
import { PriceComponent } from "../price/price.component";

@Component({
  selector: 'app-select-product-item',
  standalone: true,
  imports: [
    TranslateModule,
    NgIf,
    NgForOf,
    NgOptimizedImage,
    PriceComponent,
  ],
  templateUrl: './select-product-item.component.html',
  styleUrl: './select-product-item.component.scss',
})
export class SelectProductItemComponent {
  @Input() product!: ProductModel;
}
