import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { AppConfiguration } from '../../../app-configuration';
import { HttpClient } from '@angular/common/http';
import { CartModel } from './model/cart.model';
import { CartProductModel } from './model/cart-product.model';
import { WSPayProcessResponse, WSPayResponse } from './model/wspay.model';
import { DOCUMENT } from '@angular/common';
import { ProductsRestService } from '../products/products-rest.service';
import { TranslationService } from '../../services/translation.service';

@Injectable()
export class CartRestService {
  controllerUrl = this.appConfiguration.serverApiUrl + '/basket';

  constructor(private appConfiguration: AppConfiguration,
              private httpClient: HttpClient,
              private productsRestService: ProductsRestService,
              private translationService: TranslationService,
              @Inject(DOCUMENT) private document: Document) {
  }

  getCart(): Observable<CartModel> {
    return this.httpClient.get<CartModel>(this.controllerUrl)
  }

  create(cart: CartModel): Observable<any> {
    return this.httpClient.post<any>(this.controllerUrl + '/create', cart);
  }

  payOrder(payload: { phone: string | undefined; email: string | undefined, firstName: string, lastName: string, reEmail: string }): Observable<any> {
    const loggedUser = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      phone: payload.phone,
      email: payload.email,
      reEmail: payload.reEmail
    }
    return this.httpClient.post<any>(this.appConfiguration.serverApiUrl + `/wspay/generate-request-data`, loggedUser);
  }

  getCartHistory(sort?: string): Observable<CartProductModel[]> {
    return this.httpClient.get<CartProductModel[]>(this.appConfiguration.serverApiUrl + `/order/history?sort=${sort}`);
  }

  sendPaymentProcessResponse(params: WSPayProcessResponse): Observable<any> {
    const queryParams = {
      ShoppingCartID: params.shoppingCartId,
      Success: params.successResponse,
      DateTime: params.dateTime,
      ApprovalCode: params.approvalCode,
      Signature: params.signature,
      ErrorMessage: params.errorMessage,
      STAN: params.stan,
      WsPayOrderId: params.wsPayOrderId,
    }
    return this.httpClient.get(this.appConfiguration.serverApiUrl + `/wspay/process-response`, {params: queryParams});
  }

  paymentRedirect(params: WSPayResponse): void {
    const form = this.document.createElement("form");
    form.method = "POST";
    form.target = "_top";
    form.action = params.RequestUrl;

    for (let prop in params) {
      const input = this.document.createElement("input");
      input.type = "hidden";
      input.name = prop;
      input.value = params[prop as keyof typeof params];
      form.append(input);
    }
    this.appendUrls('ReturnURL', this.appConfiguration.ReturnURL, form);
    this.appendUrls('CancelURL', this.appConfiguration.CancelURL, form);
    this.appendUrls('ReturnErrorURL', this.appConfiguration.ReturnErrorURL, form);
    this.document.body.appendChild(form);
    form.submit();
  }

  appendUrls(name: string, value: string, form: HTMLFormElement): void {
    const input = this.document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    form.append(input);
  }
}
