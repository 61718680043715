import { NgClass, NgForOf, NgOptimizedImage } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ProductModel } from '../../../core/rest/products/model/product.model';
import { ButtonComponent } from '../../../shared/components/ui/button/button.component';
import { PriceComponent } from '../../../shared/components/ui/price/price.component';
import { TranslateModule } from '@ngx-translate/core';
import { CheckoutTotalAmountService } from '../checkout-total-amount.service';

@Component({
  selector: 'app-product-item',
  standalone: true,
  imports: [
    NgClass,
    NgOptimizedImage,
    PriceComponent,
    ButtonComponent,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    NgForOf,
  ],
  templateUrl: './product-item.component.html',
  styleUrl: './product-item.component.scss',
  encapsulation: ViewEncapsulation.None,
})

export class ProductItemComponent implements OnInit {
  @Input() product!: ProductModel;
  @Output() valueChangedEvent = new EventEmitter<void>();
  totalAmountService = inject(CheckoutTotalAmountService)
  isExpanded = false;

  ngOnInit(): void {
    if (!this.product.quantity) {
      this.product.quantity = 0;
    }
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  decreaseProductQuantity(product: ProductModel) {
    this.totalAmountService.decreaseProductQuantity(product);
  }

  increaseProductQuantity(product: ProductModel) {
    if (product.quantity < 99) {
      this.totalAmountService.increaseProductQuantity(product);
    }
  }

  onInputChange(product: ProductModel, event: any): void {
    this.totalAmountService.updateProductQuantity(product, event.target.value || 0);
  }
}
