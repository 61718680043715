import { CommonModule } from '@angular/common';
import { Component, OnInit, Provider } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TranslationService } from './core/services/translation.service';
import { MainComponent } from './shared/components/layouts/main/main.component';
import { RestModule } from './core/rest/rest.module';
import { ErrorStateMatcher } from "@angular/material/core";
import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { MatIcon, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { filter, map, take } from 'rxjs';

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && control.touched)
  }
}

export const ERROR_STATE_MATCHER_PROVIDER: Provider = {
  provide: ErrorStateMatcher,
  useValue: new MyErrorStateMatcher(),
};

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MainComponent,
    RestModule,
    MatIcon
  ],
  providers: [
    ERROR_STATE_MATCHER_PROVIDER
  ],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isTouchDevice = false;

  constructor(_translate: TranslationService,
              private iconRegistry: MatIconRegistry,
              private translationService: TranslationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private domSanitizer: DomSanitizer) {
    iconRegistry.addSvgIconSetInNamespace(
      'svg',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons.svg')
    );
  }

  ngOnInit(): void {
    this.checkTouchDevice();

    this.activatedRoute.queryParams
      .pipe(
        map(params => params['lang']),
        filter(lang => lang),
        take(1),
      )
      .subscribe(lang => {
        this.translationService.setLanguage(lang, false);
        this.router.navigate([], {queryParams: {}})
      });
  }

  checkTouchDevice() {
    this.isTouchDevice = 'ontouchstart' in window;

    if (this.isTouchDevice) {
      const rootElement = document.documentElement;
      rootElement.classList.add('is-touch-device');
    }
  }
}
