<div class="c-language-switcher">
  <app-button [matMenuTriggerFor]="languageMenu"
    [size]="'s'"
    [style]="'naked'"
    [shape]="'circle'"
    [imgSrc]="selectedFlag$ | async">
  </app-button>
  <mat-menu #languageMenu>
    @for (lang of languages; track lang.code) {
      <button mat-menu-item (click)="setLanguage(lang.code)">
        <app-language-switcher-item
          [lang]="getLangByCode(lang.code)">
        </app-language-switcher-item>
      </button>
    }
  </mat-menu>
</div>
