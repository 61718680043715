<div class="c-payment-confirmation">
  <app-triple-zone>
    <ng-template appTripleZoneHeader>
      <div class="c-payment-confirmation__logo logo-center">
        <app-logo [size]="'l'"></app-logo>
      </div>
    </ng-template>
    <ng-template appTripleZoneBody>
      <div class="c-payment-confirmation__content">
        <div class="c-payment-confirmation__content-cc">
          <img src="assets/images/icon-cc.svg" alt="cc">
        </div>
        <div class="c-payment-confirmation__content-cc-providers">
          <img ngSrc="assets/images/logo-cc-visa.svg" alt="Visa" height="16" width="16">
          <img ngSrc="assets/images/logo-cc-mastercard.svg" alt="Master Card" height="16" width="16">
        </div>
        <div class="c-payment-confirmation__content-check">
          @if (successResponse !== '1') {
            <div class="u-color-system-danger">
              <app-icon [name]="'error'" [size]="'l'"></app-icon>
            </div>
          } @else {
            <div class="u-color-system-success">
              <app-icon [name]="'check_circle'" [size]="'l'"></app-icon>
            </div>
          }
        </div>
        <div class="c-payment-confirmation__content-text">
          @if (successResponse !== '1') {
            <p>{{ 'PAYMENT_CONFIRMATION.TITLE_ERROR' | translate }}</p>
          } @else {
            <p>{{ 'PAYMENT_CONFIRMATION.TITLE_SUCCESS' | translate }}<br>
              {{ 'PAYMENT_CONFIRMATION.TITLE_SUCCESS_EMAIL' | translate }}</p>
          }
        </div>
      </div>
    </ng-template>
    <ng-template appTripleZoneFooter>
      <div class="c-payment-confirmation__footer">
        @if (successResponse === '1') {
          <div class="c-payment-confirmation__footer-wrapper">
            @if (!downloadLink) {
              <div class="c-payment-confirmation__footer-download-info-wrapper">
                <div class="c-payment-confirmation__footer-download-info">
                  <div>{{ 'PAYMENT_CONFIRMATION.LOADING_TICKET_INFO' | translate }}</div>
                  <app-progress-spinner></app-progress-spinner>
                </div>
                <div>{{ 'PAYMENT_CONFIRMATION.LOADING_TICKET_INFO_' | translate }}</div>
              </div>
            }
            <app-button
              label="{{ 'PAYMENT_CONFIRMATION.DOWNLOAD_TICKET_LABEL' | translate }}"
              [color]="'secondary'"
              [isFullWidth]="true"
              [style]="'flat'"
              [textUppercase]="false"
              [fontSize]="'m'"
              [icon]="'download'"
              [disabled]="!downloadLink"
              (clickEvent)="downloadTicket()">
            </app-button>
          </div>
        }
        <div class="c-payment-confirmation__footer__content-wrapper">
          <div class="u-link c-payment-confirmation__checkout-link"
            [routerLink]="'/checkout'">{{ 'PAYMENT_CONFIRMATION.CONTINUE_TO_CHECKOUT' | translate }}
          </div>
          <div>
            <a href="https://trajekt.me" class="u-link">Trajekt.me</a>
          </div>
        </div>
        <div class="c-payment-confirmation__content-link-container">
          <a href="https://trajekt.me/me/kontakt" class="u-link"
            target="_blank">{{ 'COMMON.CONTACT_US' | translate }}</a>
        </div>
      </div>
    </ng-template>
  </app-triple-zone>
</div>
