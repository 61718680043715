<div class="c-password-reset-confirmation">
  <div class="c-password-reset-confirmation__logo">
    <app-logo [size]="'l'"></app-logo>
  </div>
  <div class="c-password-reset-confirmation__content">
    <div class="c-password-reset-confirmation__content-check">
      <img src="assets/images/icon-check.svg">
    </div>
    <div class="c-password-reset-confirmation__content-text">
      <p>{{ 'PASSWORD_RESET_CONFIRMATION.TITLE' | translate }}</p>
    </div>
  </div>
</div>
