import { Injectable } from '@angular/core';
import { AppConfiguration } from '../../../app-configuration';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductModel } from './model/product.model';

@Injectable()
export class ProductsRestService {
  controllerUrl = this.appConfiguration.serverApiUrl + '/product';

  constructor(private appConfiguration: AppConfiguration,
              private httpClient: HttpClient) {
  }

  getProducts(): Observable<ProductModel[]> {
    return this.httpClient.get<ProductModel[]>(this.controllerUrl);
  }

  getTicketValidityPeriod(): Observable<{ purchasedTicketsValidTo: string }> {
    return this.httpClient.get<{ purchasedTicketsValidTo: string }>(`${this.appConfiguration.serverApiUrl}/shop-settings`)
  }
}
