import { NgClass, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-logo',
  standalone: true,
  imports: [
    NgOptimizedImage,
    RouterLink,
    NgClass,
  ],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss',
})
export class LogoComponent {
  @Input() size: 's' | 'm' | 'l' = 'm';
  @Input() isLink = false;
}
