import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { map, Observable } from 'rxjs';
import { Lang, LangCodesEnum, LANGUAGES_LIST, TranslationService } from '../../../../core/services/translation.service';
import { ButtonComponent } from '../button/button.component';
import { LanguageSwitcherItemComponent } from './language-switcher-item/language-switcher-item.component';

@Component({
  selector: 'app-language-switcher',
  standalone: true,
  imports: [
    ButtonComponent,
    MatMenuModule,
    AsyncPipe,
    LanguageSwitcherItemComponent,
  ],
  templateUrl: './language-switcher.component.html',
  styleUrl: './language-switcher.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent implements OnInit {
  languages: Lang[] = LANGUAGES_LIST;

  selectedFlag$: Observable<string> = this.translationService.getSelectedLanguage().pipe(map((lang: Lang) => lang.flag as string));

  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {
    this.initDefaultLanguage();
  }

  private initDefaultLanguage(): void {
    this.translationService.setLanguage(this.translationService.getSelectedLanguageFromLocalStorage() as LangCodesEnum, false);
  }

  setLanguage(languageCode: LangCodesEnum): void {
    this.translationService.setLanguage(languageCode, true);
  }

  getLangByCode = (langCode: LangCodesEnum): Lang => {
    return this.languages.find((lang: Lang) => lang.code === langCode) ?? this.languages[0];
  };
}
