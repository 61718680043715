import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  TemplateContainerComponent,
} from '../../shared/components/layouts/template-container/template-container.component';
import { ButtonComponent } from '../../shared/components/ui/button/button.component';
import {
  TextStrikeThroughComponent,
} from '../../shared/components/ui/text-strike-through/text-strike-through.component';
import { NgIf } from '@angular/common';
import { CustomerRestService } from '../../core/rest/customer/customer-rest.service';
import {AuthService} from "../../core/rest/auth/auth.service";
import { catchError, map, throwError } from 'rxjs';
import { CustomerModel } from '../../core/models/customer/customer.model';
import { SnackBarService } from '../../core/services/snackbar.service';
import { EMAIL_PATTERN } from '../../core/utils/form-patterns';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    RouterLink,
    TemplateContainerComponent,
    ButtonComponent,
    MatInputModule,
    TextStrikeThroughComponent,
    MatIconModule,
    MatButtonModule,
    TemplateContainerComponent,
    ButtonComponent,
    TextStrikeThroughComponent,
    TranslateModule,
    ReactiveFormsModule,
    NgIf,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit, AfterViewInit {

  form!: FormGroup;
  hide = true;

  constructor(private customerRestService: CustomerRestService,
              private router: Router,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private translateService: TranslateService,
              private snackBarService: SnackBarService) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
      password: new FormControl('', [Validators.required]),
    });

    let verificationCode;
    this.activatedRoute.queryParams.subscribe(param => {
      verificationCode = param['verificationCode'];
    });
    if (verificationCode) {
      this.customerRestService.verifyCustomer(verificationCode)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            this.router.navigate(['/account/activation'])
            return throwError(() => err);
          }),
          map(response => response.statusCode === 200)
        )
        .subscribe(isVerified => {
          if (isVerified) {
            this.snackBarService.showSuccessMessage(this.translateService.instant('ACCOUNT.ACTIVATION_CONFIRM'));
            this.authService.setRegisterMail('');
          }
        });
    } else if (verificationCode === '') {
      this.router.navigate(['/account/activation'])
    }
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    const loginCredentials  = {
      email: this.form.value.email,
      password: this.form.value.password,
    }

    this.customerRestService.login(loginCredentials)
      .pipe(catchError((err) => {
        const message = this.customerRestService.getErrorMessage(err);
        this.snackBarService.showErrorMessage(message);
        return throwError(() => err);
      }))
      .subscribe((response: CustomerModel) => {
        if (response) {
          this.snackBarService.showSuccessMessage(this.translateService.instant('LOGIN.LOGIN_SUCCESSFUL'));
          this.authService.login(response);
          this.router.navigate(['/checkout']);
        }
      });
  }

  ngAfterViewInit() {
    const googleBtnLogin = document.getElementById("signInGoogleBtnLogin")
    this.authService.googleAuthInit(googleBtnLogin)
  }

  getEmailPattern() {
    return this.form.get('email')?.value.match(EMAIL_PATTERN);
  }
}
