<div class="c-register">
  <form [formGroup]="registerForm">
    <app-template-container>
      <h1 class="c-page-title">{{ 'COMMON.REGISTER' | translate }}</h1>

      <div class="u-form-element-wrapper">
        <mat-label>{{ 'COMMON.FORM_FIELDS.FIRST_NAME.LABEL' | translate }} <span
          class="u-form-element-required-star">*</span></mat-label>
        <mat-form-field class="u-form-field-full" appearance="outline">
          <input type="text" formControlName="firstName" matInput
            placeholder="{{ 'COMMON.FORM_FIELDS.FIRST_NAME.PLACEHOLDER' | translate }}">
        </mat-form-field>
        @if (registerForm.get('firstName')?.invalid && registerForm.get('firstName')?.touched) {
          <mat-error *ngIf="registerForm.get('firstName')?.hasError('required')">
            {{ 'COMMON.FORM_FIELDS.FIRST_NAME.REQUIRED_MESSAGE' | translate }}
          </mat-error>
        }
      </div>

      <div class="u-form-element-wrapper">
        <mat-label>{{ 'COMMON.FORM_FIELDS.LAST_NAME.LABEL' | translate }} <span
          class="u-form-element-required-star">*</span></mat-label>
        <mat-form-field class="u-form-field-full" appearance="outline">
          <input type="text" formControlName="lastName" matInput
            placeholder="{{ 'COMMON.FORM_FIELDS.LAST_NAME.PLACEHOLDER' | translate }}">
        </mat-form-field>
        @if (registerForm.get('lastName')?.invalid && registerForm.get('lastName')?.touched) {
          <mat-error *ngIf="registerForm.get('lastName')?.hasError('required')">
            {{ 'COMMON.FORM_FIELDS.LAST_NAME.REQUIRED_MESSAGE' | translate }}
          </mat-error>
        }
      </div>

      <div class="u-form-element-wrapper">
        <app-phone-field
          [label]="'COMMON.FORM_FIELDS.PHONE.LABEL' | translate"
          [placeholder]="'COMMON.FORM_FIELDS.PHONE.PLACEHOLDER' | translate"
          [isFullWide]="true"
          [formControlName]="'phone'">
        </app-phone-field>
      </div>

      <div class="u-form-element-wrapper">
        <mat-label>{{ 'COMMON.FORM_FIELDS.EMAIL.LABEL' | translate }} <span
          class="u-form-element-required-star">*</span></mat-label>
        <mat-form-field class="u-form-field-full" appearance="outline">
          <input type="text" formControlName="email" matInput
            placeholder="{{ 'COMMON.FORM_FIELDS.EMAIL.PLACEHOLDER' | translate }}">
        </mat-form-field>
        @if (registerForm.get('email')?.invalid && registerForm.get('email')?.touched) {
          <mat-error *ngIf="registerForm.get('email')?.hasError('required')">
            {{ 'COMMON.FORM_FIELDS.EMAIL.REQUIRED_MESSAGE' | translate }}
          </mat-error>
          @if (!getEmailPattern()) {
            <mat-error>{{ 'COMMON.FORM_FIELDS.EMAIL.EMAIL_FORMAT_MESSAGE' | translate }}</mat-error>
          }
        }
      </div>

      <div class="u-form-element-wrapper">
        <mat-label>{{ 'COMMON.FORM_FIELDS.NEW_PASSWORD.LABEL' | translate }} <span class="u-form-element-required-star">*</span>
        </mat-label>
        <mat-form-field class="u-form-field-full" appearance="outline">
          <input matInput placeholder="{{ 'COMMON.FORM_FIELDS.NEW_PASSWORD.PLACEHOLDER' | translate }}"
            [type]="hide ? 'password' : 'text'" formControlName="password" required>
          <button matSuffix mat-icon-button (click)="hide = !hide" type="button">
            <mat-icon [svgIcon]="hide ? 'svg:visibility_off' : 'svg:visibility'"></mat-icon>
          </button>
        </mat-form-field>
        @if (registerForm.get('password')?.invalid && registerForm.get('password')?.touched) {
          <mat-error *ngIf="registerForm.get('password')?.hasError('required')">
            {{ 'COMMON.FORM_FIELDS.PASSWORD.REQUIRED_MESSAGE' | translate }}
          </mat-error>
        }
        @if (registerForm.get('password')?.invalid && registerForm.get('password')?.touched) {
          @if (!getPatternMessage('^(?=.*[A-Z])')) {
            <mat-error>{{ 'COMMON.FORM_FIELDS.PASSWORD.PASSWORD_FORMAT_UPPERCASE' | translate }}</mat-error>
          }
          @if (!getPatternMessage('(?=.*[a-z])')) {
            <mat-error>{{ 'COMMON.FORM_FIELDS.PASSWORD.PASSWORD_FORMAT_LOWERCASE' | translate }}</mat-error>
          }
          @if (!getPatternMessage('(.*[0-9].*)')) {
            <mat-error>{{ 'COMMON.FORM_FIELDS.PASSWORD.PASSWORD_FORMAT_DIGIT' | translate }}</mat-error>
          }
          @if (!getPatternMessage('(?=.*[!@#$%^&*])')) {
            <mat-error>{{ 'COMMON.FORM_FIELDS.PASSWORD.PASSWORD_FORMAT_SPECIAL_CHAR' | translate }}</mat-error>
          }
          @if (!getPatternMessage('.{8,}')) {
            <mat-error>{{ 'COMMON.FORM_FIELDS.PASSWORD.PASSWORD_FORMAT_STRENGTH' | translate }}</mat-error>
          }
        }
      </div>

      <div class="u-form-element-wrapper">
        <mat-label>{{ 'COMMON.FORM_FIELDS.CONFIRM_NEW_PASSWORD.LABEL' | translate }} <span
          class="u-form-element-required-star">*</span></mat-label>
        <mat-form-field class="u-form-field-full" appearance="outline">
          <input matInput placeholder="{{ 'COMMON.FORM_FIELDS.CONFIRM_NEW_PASSWORD.PLACEHOLDER' | translate }}"
            [type]="hideRepeat ? 'password' : 'text'" formControlName="rePassword" required>
          <button matSuffix mat-icon-button (click)="hideRepeat = !hideRepeat" type="button">
            <mat-icon [svgIcon]="hideRepeat ? 'svg:visibility_off' : 'svg:visibility'"></mat-icon>
          </button>
        </mat-form-field>
        @if (registerForm.get('rePassword')?.invalid && registerForm.get('rePassword')?.touched) {
          <mat-error *ngIf="registerForm.get('rePassword')?.hasError('required')">
            {{ 'COMMON.FORM_FIELDS.CONFIRM_NEW_PASSWORD.REQUIRED_MESSAGE' | translate }}
          </mat-error>
        }
        @if (registerForm.invalid && registerForm.get('rePassword')?.touched && registerForm.hasError('passwordsMismatch')) {
          <mat-error> {{ 'COMMON.FORM_FIELDS.CONFIRM_NEW_PASSWORD.PASSWORD_PATTERN_MESSAGE' | translate }}</mat-error>
        }
      </div>

      <div class="u-form-element-wrapper">
        <mat-label>{{ 'COMMON.FORM_FIELDS.VEHICLE_CATEGORIES.LABEL' | translate }}</mat-label>
        <mat-form-field class="u-form-field-full" appearance="outline">
          <mat-select formControlName="idVehicle"
            placeholder="{{ 'COMMON.FORM_FIELDS.VEHICLE_CATEGORIES.PLACEHOLDER' | translate }}">
            @for (product of products | sortByPrice; track product.id; ) {
              <mat-option [value]="product.id">
                <app-select-product-item [product]="product"></app-select-product-item>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="u-form-checkbox-container">
        <mat-checkbox [formControlName]="'termsOfUse'"></mat-checkbox>
        <div>{{ 'COMMON.FORM_FIELDS.TERMS.LABEL' | translate }}
          <a class="u-link" target="_blank"
             [href]="(this.translationService.selectedLanguage$ | async)?.code === LangCodesEnum.MN ? appConfig.termsMe : appConfig.termsEn">
            {{ 'COMMON.FORM_FIELDS.TERMS.LINK_TEXT' | translate }}
          </a>
        </div>

      </div>
      <div class="u-form-checkbox-container">
        <mat-checkbox [formControlName]="'privacyPolicy'"></mat-checkbox>
        <div>{{ 'COMMON.FORM_FIELDS.PRIVACY.LABEL' | translate }}
          <a class="u-link" target="_blank"
             [href]="(this.translationService.selectedLanguage$ | async)?.code === LangCodesEnum.MN ? appConfig.privacyMe : appConfig.privacyEn">
            {{ 'COMMON.FORM_FIELDS.PRIVACY.LINK_TEXT' | translate }}
          </a>
        </div>
      </div>

      <app-text-strike-through label="{{ 'COMMON.OR' | translate }}"></app-text-strike-through>
      <div id="googleBtnRegister" class="google-btn"></div>

      <div class="u-form-element-wrapper">
        <app-button label="{{ 'COMMON.REGISTER' | translate }}"
          [isFullWidth]="true"
          (clickEvent)="register()"
          [disabled]="!registerForm.valid">
        </app-button>
      </div>
    </app-template-container>
  </form>
</div>
