import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { SnackBarService } from "../services/snackbar.service";
import { Router } from "@angular/router";
import { getLanguage, LANGUAGE_HEADER } from '../utils/language.util';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(public authService: AuthService,
              private router: Router,
              private snackBarService: SnackBarService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = this.cloneRequest(request);
    return next.handle(clonedRequest)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 0) {
            this.snackBarService.showErrorMessage('Server is down');
          }

          if (error.status === 401) {
            this.authService.logout();
            this.router.navigate(['checkout'])
          }

          if (error.status === 403) {
            this.router.navigate(['checkout']);
          }

          if (error.status === 404) {
            this.router.navigate(['not-found']); //or checkout
          }

          if (error.status == 500) {
            const message = 'Server error'
            this.snackBarService.showErrorMessage(message);
          }

          return throwError(() => error);
        })
      )
  }

  private cloneRequest(request: HttpRequest<any>): HttpRequest<any> {
    const language = getLanguage();
    let headers: HttpHeaders = request.headers
      .set(LANGUAGE_HEADER, language)

    return request.clone({
      headers,
    });
  }
}
