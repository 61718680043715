<div class="c-dual-zone"
  [ngClass]="[
    !!bodyDirective && !footerDirective ? 'c-dual-zone--has-body' : '',
    !!footerDirective && !bodyDirective ? 'c-dual-zone--has-footer' : '',
    !!bodyDirective && !!footerDirective ? 'c-dual-zone--has-body-and-footer' : '',
  ]">
  @if (!!bodyDirective) {
    <div class="c-dual-zone__body">
      <ng-container [ngTemplateOutlet]="bodyDirective.tplRef"></ng-container>
    </div>
  }
  @if (!!footerDirective) {
    <div class="c-dual-zone__footer">
      <ng-container [ngTemplateOutlet]="footerDirective.tplRef"></ng-container>
    </div>
  }
</div>
