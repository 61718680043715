<div class="c-account-activation-failed">
  <div class="c-account-activation-failed__logo">
    <app-logo [size]="'l'"></app-logo>
  </div>
  <div class="c-account-activation-failed__content">
    <div class="c-account-activation-failed__content-text">
      <p>{{ 'ACCOUNT.ACTIVATION_FAILED' | translate }}
      <a class="c-account-activation-failed__content-link" (click)="sendActivationLink()">
        {{ 'ACCOUNT.ACTIVATION_FAILED_LINK' | translate }}</a>
      </p>
    </div>
  </div>
</div>
