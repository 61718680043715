import {Component} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {AuthService} from "../../../../core/rest/auth/auth.service";
import {AsyncPipe, NgIf} from "@angular/common";
import {Observable} from "rxjs";
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-user-menu',
  standalone: true,
  imports: [
    RouterLink,
    MatMenuModule,
    TranslateModule,
    AsyncPipe,
    NgIf,
    ButtonComponent,
  ],
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
})
export class UserMenuComponent {
  user = this.authService.user;
  isLoggedIn: Observable<boolean> = this.authService.getIsLoggedIn;

  constructor(private authService: AuthService) {
  }

  logOut() {
    this.authService.logout();
  }


}
