import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appTripleZoneHeader]',
  standalone: true,
})
export class TripleZoneHeaderDirective {

  constructor(public tplRef: TemplateRef<any>) { }

}
