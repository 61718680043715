<div class="c-tickets">
  <app-dual-zone>
    <ng-template appDualZoneBody>
      <app-panel title="{{ 'TICKETS.TITLE' | translate }}" (sortByEvent)="onSortTicketEvent($event)">
        <div class="c-tickets__items">
          @for (ticket of tickets; track ticket.idProduct; let i = $index) {
            <app-ticket
              [cartItem]="ticket"
              [ticketPurchaseDate]="ticket.createdAt"
              [validFrom]="ticket.validFrom"
              [validTo]="ticket.validTo">
            </app-ticket>
          } @empty {
            <p>{{ 'TICKETS.NO_TICKETS' | translate }}</p>
          }
        </div>
      </app-panel>
    </ng-template>
  </app-dual-zone>
</div>
