<div class="c-login">
  <app-template-container>
    <h1 class="c-page-title u-text-center">{{ 'LOGIN.TITLE' | translate }}</h1>
    <div class="u-form-element-wrapper">
      <div id="signInGoogleBtnLogin" class="google-btn" data-width="320"></div>
    </div>

    <app-text-strike-through label="{{ 'COMMON.OR' | translate }}"></app-text-strike-through>

    <form [formGroup]="form">
      <div class="u-form-element-wrapper">
        <mat-label>{{ 'COMMON.FORM_FIELDS.EMAIL.LABEL' | translate }} <span class="u-form-element-required-star">*</span></mat-label>
        <mat-form-field class="u-form-field-full" appearance="outline">
          <input type="text" matInput placeholder="{{ 'COMMON.FORM_FIELDS.EMAIL.PLACEHOLDER' | translate }}" [formControlName]="'email'">
        </mat-form-field>
        @if (form.get('email')?.invalid && form.get('email')?.touched) {
          <mat-error *ngIf="form.get('email')?.hasError('required')">
            {{ 'COMMON.FORM_FIELDS.EMAIL.REQUIRED_MESSAGE' | translate }}
          </mat-error>
          @if (!getEmailPattern()) {
            <mat-error>{{'COMMON.FORM_FIELDS.EMAIL.EMAIL_FORMAT_MESSAGE' | translate}}</mat-error>
          }
        }
      </div>

      <div class="u-form-element-wrapper">
        <mat-label>{{ 'COMMON.FORM_FIELDS.PASSWORD.LABEL' | translate }} <span class="u-form-element-required-star">*</span></mat-label>
        <mat-form-field class="u-form-field-full" appearance="outline">
          <input matInput placeholder="{{ 'COMMON.FORM_FIELDS.PASSWORD.PLACEHOLDER' | translate }}" [type]="hide ? 'password' : 'text'" [formControlName]="'password'" required>
          <button matSuffix mat-icon-button (click)="hide = !hide" type="button">
            <mat-icon [svgIcon]="hide ? 'svg:visibility_off' : 'svg:visibility'"></mat-icon>
          </button>
        </mat-form-field>
        @if(form.get('password')?.invalid && form.get('password')?.touched) {
          <mat-error *ngIf="form.get('password')?.hasError('required')">
            {{ 'COMMON.FORM_FIELDS.EMAIL.REQUIRED_MESSAGE' | translate }}
          </mat-error>
        }
        <div class="login-forgot-password">
          <span class="u-link" [routerLink]="'/password/reset'">{{ 'LOGIN.FORGOT_PASSWORD' | translate }}</span>
        </div>
      </div>

      <div class="u-form-element-wrapper">
        <app-button label="{{ 'COMMON.LOGIN' | translate }}"
          [isFullWidth]="true"
          [disabled]="!form.valid"
          (clickEvent)="onSubmit()">
        </app-button>
      </div>
      <div>
        <p class="u-text-center">{{ 'LOGIN.YOU_DONT_HAVE_ACCOUNT' | translate }} <span class="u-link" [routerLink]="'/register'">{{ 'COMMON.REGISTER' | translate }}</span></p>
      </div>
    </form>
  </app-template-container>
</div>
