<div class="c-user-menu">
  <app-button
    [matMenuTriggerFor]="userMenu"
    [icon]="'person'"
    [size]="'s'"
    [style]="'flat'"
    [shape]="'circle'"
    [color]="'neutral'"
    [attr.title]="'USER_MENU.LOGIN_OR_REGISTER' | translate">
  </app-button>
  <mat-menu #userMenu>
    <button mat-menu-item [routerLink]="'/login'" *ngIf="!(isLoggedIn | async)">{{ 'COMMON.LOGIN' | translate }}</button>
    <button mat-menu-item [routerLink]="'/register'" *ngIf="!(isLoggedIn | async)">{{ 'COMMON.REGISTER' | translate }}</button>
    <button mat-menu-item [routerLink]="'/checkout'">{{ 'USER_MENU.CHECKOUT' | translate }}</button>
    <button mat-menu-item [routerLink]="'/tickets'" *ngIf="isLoggedIn | async">{{ 'TICKETS.TITLE' | translate }}</button>
    <button mat-menu-item [routerLink]="'/account'" *ngIf="isLoggedIn | async">{{ 'ACCOUNT.PROFILE_SETTINGS' | translate }}</button>
    <button mat-menu-item [routerLink]="'/account/password'" *ngIf="isLoggedIn | async">{{ 'PASSWORD_CHANGE.TITLE' | translate }}</button>
    <button mat-menu-item [routerLink]="'/login'" *ngIf="isLoggedIn | async" (click)="logOut()">{{ 'COMMON.LOGOUT' | translate }}</button>
  </mat-menu>
</div>
