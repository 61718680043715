import { NgTemplateOutlet } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LangCodesEnum } from '../../core/services/translation.service';

@Component({
  selector: 'app-privacy',
  standalone: true,
  imports: [
    NgTemplateOutlet,
  ],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss',
})
export class PrivacyComponent implements OnInit, OnDestroy {
  private readonly translate = inject(TranslateService);
  readonly langCodesEnum = LangCodesEnum;
  currentLang = this.translate.currentLang;

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });
  }

  ngOnDestroy(): void {
    // If unsubscribe there we get an error.
    // this.translate.onLangChange.unsubscribe();
  }
}
