import {Injectable, NgZone} from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, throwError } from "rxjs";
import {CustomerRestService} from "../customer/customer-rest.service";
import {AppConfiguration} from "../../../app-configuration";
import {Router} from "@angular/router";
import {CustomerModel} from "../../models/customer/customer.model";
import {HttpErrorResponse} from "@angular/common/http";
import {SnackBarService} from "../../services/snackbar.service";

class GoogleUser {
  id?: string;
  email?: string;
  name?: string;
  picture?: string
  family_name?: string;
  given_name? :string;
  lastName?: string;
  iss?: string
  azp?: string
  aud?: string;
  sub?: string;
}

export const buttonConfig = {
  size: "large",
  type: "standard",
  shape: "rectangular",
  text: "signin_with",
  theme: "outline",
  logo_alignment: "center",
  width: "320",
}

declare const google: any;
@Injectable()
export class AuthService {
  private userSubject$: BehaviorSubject<CustomerModel | null> = new BehaviorSubject<CustomerModel | null>(null);
  private registerEmail$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  idConfiguration = {
    client_id: this.appConfiguration.googleClientId,
    callback:(response: any) => this.onClickSignInHandler(response)
  }

  constructor(private customerRestService: CustomerRestService,
              private appConfiguration: AppConfiguration,
              private router: Router,
              private ngZone: NgZone,
              private snackBarService: SnackBarService) {
    google.accounts.id.initialize(this.idConfiguration);
  }

  public get user() {
    return this.userSubject$;
  }

  public get getIsLoggedIn() {
    return this.userSubject$
      .pipe(map(user => !!user));
  }

  googleAuthInit(googleBtn:HTMLElement | null) {
    google.accounts.id.renderButton(googleBtn, buttonConfig);
  }

  onClickSignInHandler(response: any) {
    const token = response.credential;
    this.ngZone.run(() => {
      this.customerRestService.loginWithGoogle(token)
        .pipe(
          catchError((err: HttpErrorResponse)=> {
            const message = this.customerRestService.getErrorMessage(err);
            this.snackBarService.showErrorMessage(message);
            return throwError(() => err);
          })
        )
        .subscribe((user:CustomerModel) => {
          this.router.navigate(['checkout']);
          this.userSubject$.next(user);
        });
    })
  }

  logout () {
    this.user.next(null);
    google.accounts.id.disableAutoSelect();
    this.customerRestService.logout().subscribe()
  }

  login(loggedUser: CustomerModel): void {
    this.user.next(loggedUser);
  }

  get registerEmail(): Observable<string> {
    return this.registerEmail$;
  }

  setRegisterMail(email: string): void {
    this.registerEmail$.next(email);
  }
}
