import { Component, HostListener, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DualZoneBodyDirective } from '../../shared/components/layouts/dual-zone/dual-zone-body.directive';
import { DualZoneFooterDirective } from '../../shared/components/layouts/dual-zone/dual-zone-footer.directive';
import { DualZoneComponent } from '../../shared/components/layouts/dual-zone/dual-zone.component';
import { ButtonComponent } from '../../shared/components/ui/button/button.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Location, NgClass, NgIf } from '@angular/common';
import { catchError, throwError } from 'rxjs';
import { CartRestService } from '../../core/rest/cart/cart-rest.service';
import { CustomerRestService } from '../../core/rest/customer/customer-rest.service';
import { SnackBarService } from '../../core/services/snackbar.service';
import { WSPayResponse } from '../../core/rest/cart/model/wspay.model';
import { AuthService } from '../../core/rest/auth/auth.service';
import { CustomerModel } from '../../core/models/customer/customer.model';
import { EMAIL_PATTERN } from '../../core/utils/form-patterns';
import { PhoneFieldComponent } from '../../shared/components/ui/phone-field/phone-field.component';
import { confirmEmailValidator } from '../../core/utils/form-inputs-validator';

@Component({
  selector: 'app-payment-info',
  standalone: true,
  imports: [
    RouterLink,
    DualZoneComponent,
    DualZoneBodyDirective,
    DualZoneFooterDirective,
    ButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    ReactiveFormsModule,
    NgIf,
    FormsModule,
    NgClass,
    PhoneFieldComponent,
  ],
  templateUrl: './payment-info.component.html',
  styleUrl: './payment-info.component.scss',
})
export class PaymentInfoComponent implements OnInit {
  @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {
    if (this.checkInputFields(event)) {
      event.preventDefault();
    }
  }

  @HostListener('copy', ['$event']) blockCopy(event: ClipboardEvent) {
    if (this.checkInputFields(event)) {
      event.preventDefault();
    }
  }

  form!: FormGroup;
  loggedUser!: CustomerModel | null;

  constructor(private cartRestService: CartRestService,
              private customerRestService: CustomerRestService,
              private snackBarService: SnackBarService,
              private authService: AuthService,
              private location: Location) {
  }

  ngOnInit(): void {
    this.authService.user.subscribe(loggedUser => {
      this.loggedUser = loggedUser;
    });
    this.form = new FormGroup({
      firstName: new FormControl(this.loggedUser ? this.loggedUser.firstName : '', Validators.required),
      lastName: new FormControl(this.loggedUser ? this.loggedUser.lastName : '', Validators.required),
      email: new FormControl(this.loggedUser ? this.loggedUser.email : '', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
      reEmail: new FormControl(this.loggedUser ? this.loggedUser.email : '', [Validators.required]),
      phone: new FormControl(this.loggedUser ? this.loggedUser.phone : ''),
    }, {validators: confirmEmailValidator});
  }

  private checkInputFields(event: ClipboardEvent): boolean {
    const target = event.target as HTMLInputElement;
    const id = target?.id || '';

    return id === 'email' || id === 'reEmail';
  }

  onPayClick(): void {
    const user = {
      firstName: this.form.get('firstName')?.value,
      lastName: this.form.get('lastName')?.value,
      email: this.form.get('email')?.value,
      reEmail: this.form.get('reEmail')?.value,
      phone: this.form.get('phone')?.value
    }

    this.cartRestService.payOrder(user)
      .pipe(
        catchError(err => {
          const message = this.customerRestService.getErrorMessage(err);
          this.snackBarService.showErrorMessage(message);
          return throwError(() => err);
        })
      )
      .subscribe((response: WSPayResponse) => {
        if (response) {
          this.cartRestService.paymentRedirect(response);
        }
      });
  }

  onBackClick(): void {
    this.location.back();
  }

  getEmailPattern() {
    return this.form.get('email')?.value.match(EMAIL_PATTERN);
  }
}
