<div class="c-footer-info">
  <div class="c-footer-info__container">
    <div class="c-footer-info__company">
      <h4>{{ 'FOOTER.INFO_TITLE' | translate }}</h4>
      <p>{{ 'FOOTER.COMPANY_TITLE' | translate }}</p>
      <p>RB: 80010870<br>PIB: 02116146</p>
      <p>Ulica Popa Jola Zeca bb, 85310 Budva, Crna Gora</p>
      <p>
        <a href="tel:+38268848572">+382 688 485 72</a><br>
        <a href="tel:+38233452709">+382 334 527 09</a><br>
        <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#116;&#114;&#97;&#106;&#101;&#107;&#116;&#64;&#109;&#111;&#114;&#115;&#107;&#111;&#100;&#111;&#98;&#114;&#111;&#46;&#99;&#111;&#109;">trajekt&#64;morskodobro.com</a><br>
        <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#106;&#112;&#109;&#100;&#64;&#109;&#111;&#114;&#115;&#107;&#111;&#100;&#111;&#98;&#114;&#111;&#46;&#99;&#111;&#109;">jpmdcg&#64;t-com.me</a>
      </p>
    </div>
    <div class="c-footer-info__logo">
      <app-logo [size]="'l'" [isLink]="true"></app-logo>
    </div>
    <div class="c-footer-info__links">
      <h4>{{ 'FOOTER.LINKS_TITLE' | translate }}</h4>
      <ul class="c-footer-info__links-list">
        <li>
          <a class="u-link" target="_blank" [href]="(this.translationService.selectedLanguage$ | async)?.code === LangCodesEnum.MN ? appConfig.termsMe : appConfig.termsEn">
          {{ 'COMMON.FORM_FIELDS.TERMS.LINK_TEXT_NAME' | translate }}
        </a>
        </li>
        <li>
          <a class="u-link" target="_blank"  [href]="(this.translationService.selectedLanguage$ | async)?.code === LangCodesEnum.MN ? appConfig.privacyMe : appConfig.privacyEn">
            {{ 'COMMON.FORM_FIELDS.PRIVACY.LINK_TEXT_NAME' | translate }}
          </a></li>
        <li><a href="https://trajekt.me" class="u-link" target="_blank">Trajekt.me</a></li>
      </ul>
    </div>
  </div>
  <div class="c-footer-info__payment">
    <div class="c-footer-info__payment-types">
      <a href="https://www.visasoutheasteurope.com/montenegro.html" title="Visa" target="_blank">
        <img ngSrc="assets/images/icons-payment/visa.svg" height="50" width="80" alt="">
      </a>
      <a href="https://www.mastercard.me/me-me.html" title="Mastercard" target="_blank">
        <img ngSrc="assets/images/icons-payment/mastercard.svg" height="50" width="80" alt="">
      </a>
      <a href="https://www.mastercard.me/me-me/korisnici/podrska/sigurnost-i-zastita/identity-check.html" title="MastercardID" target="_blank">
        <img ngSrc="assets/images/icons-payment/mastercard-id.svg" height="50" width="50" alt="">
      </a>
      <a href="https://www.visa.co.uk/products/visa-secure.html" title="Visa Secure" target="_blank">
        <img ngSrc="assets/images/icons-payment/visa-secure.png" height="50" width="73" alt="">
      </a>
      <a href="https://www.wspay.me" title="Monri WSpay - Web Secure Payment Gateway" target="_blank">
        <img alt="Monri WSpay - Web Secure Payment Gateway" src="https://www.wspay.info/payment-info/wsPayWebSecureLogo-118x50-transparent.png" height="50" width="118">
      </a>
    </div>
    <p>{{ 'FOOTER.PRICING_INFO' | translate }}</p>
  </div>
</div>
