<div class="c-password-set-new-confirmation">
  <div class="c-password-set-new-confirmation__logo">
    <app-logo [size]="'l'"></app-logo>
  </div>
  <div class="c-password-set-new-confirmation__content">
    <div class="c-password-set-new-confirmation__content-check">
      <img ngSrc="assets/images/icon-check.svg" height="32" width="32" alt="">
    </div>
    <div class="c-password-set-new-confirmation__content-text">
      <p>{{ 'PASSWORD_SET_NEW_CONFIRMATION.TITLE' | translate }}</p>
      <a class="u-link" [routerLink]="'/login'">{{ 'PASSWORD_SET_NEW_CONFIRMATION.GO_TO_LOGIN' | translate }}</a>
    </div>
  </div>
</div>
