import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, switchMap, throwError } from 'rxjs';
import { ProductModel } from '../../core/rest/products/model/product.model';
import { DualZoneBodyDirective } from '../../shared/components/layouts/dual-zone/dual-zone-body.directive';
import { DualZoneFooterDirective } from '../../shared/components/layouts/dual-zone/dual-zone-footer.directive';
import { DualZoneComponent } from '../../shared/components/layouts/dual-zone/dual-zone.component';
import { ButtonComponent } from '../../shared/components/ui/button/button.component';
import { PanelComponent } from '../../shared/components/ui/panel/panel.component';
import {
  SelectProductItemComponent
} from '../../shared/components/ui/select-product-item/select-product-item.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CustomerRestService } from "../../core/rest/customer/customer-rest.service";
import { CustomerModel } from "../../core/models/customer/customer.model";
import { ProductsRestService } from "../../core/rest/products/products-rest.service";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackBarService } from "../../core/services/snackbar.service";
import { SortByPricePipe } from "../../shared/pipes/sort-by-price.pipe";
import { PhoneFieldComponent } from '../../shared/components/ui/phone-field/phone-field.component';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [
    PanelComponent,
    ButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    DualZoneComponent,
    DualZoneBodyDirective,
    DualZoneFooterDirective,
    MatOptionModule,
    MatSelectModule,
    DualZoneComponent,
    PanelComponent,
    ButtonComponent,
    DualZoneFooterDirective,
    DualZoneBodyDirective,
    AsyncPipe,
    SelectProductItemComponent,
    NgIf,
    TranslateModule,
    ReactiveFormsModule,
    SortByPricePipe,
    PhoneFieldComponent,
  ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss'
})
export class AccountComponent implements OnInit {

  accountForm!: FormGroup;
  customer!: CustomerModel;
  products: ProductModel[] = [];

  constructor(private customerRestService: CustomerRestService,
              private productsRestService: ProductsRestService,
              private snackBarService: SnackBarService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.initForm();

    this.customerRestService.getAccount()
      .pipe(
        switchMap(customer => {
          this.customer = customer;
          this.accountForm.patchValue(this.customer);
          return this.productsRestService.getProducts();
        }),
        catchError((err: HttpErrorResponse) => {
          this.snackBarService.showErrorMessage('An error has occurred');
          return throwError(() => err);
        })
      ).subscribe(products => {
        this.products = products;
      });
  }

  initForm() {
    this.accountForm = new FormGroup({
      email: new FormControl({value: '', disabled: true}),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required]),
      idVehicle: new FormControl(null)
    })
  }

  save(): void {
    if (!this.accountForm.valid) return;
    const customerAccount: CustomerModel = this.accountForm.value;
    this.customerRestService.saveAccount(customerAccount)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          const message = this.customerRestService.getErrorMessage(err);
          this.snackBarService.showErrorMessage(message);
          return throwError(() => err);
        })
      )
      .subscribe((data: CustomerModel) => {
        const message = this.translateService.instant('ACCOUNT.UPDATE_SUCCESSFUL')
        this.snackBarService.showSuccessMessage(message)
      });
  }
}
