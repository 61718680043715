import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) {}

  showSuccessMessage(message: string): void {
    const config = { panelClass: 'mat-snackbar-success'}
    this.snackBar.open(message, '', config);
  }

  showErrorMessage(message: string): void {
    const config = { panelClass: 'mat-snackbar-error'}
    this.snackBar.open(message, '', config);
  }
}
