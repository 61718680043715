export const DEFAULT_LANGUAGE = 'mn';
export const LANGUAGE_HEADER = 'language';

export function getLanguage(): string {
  return localStorage.getItem('language') || DEFAULT_LANGUAGE;
}

export function setLanguage(lang: string, reloadApp = true): void {
  localStorage.setItem('language', lang);
  if (reloadApp) {
    window.location.reload()
  }
}
