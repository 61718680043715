import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { DualZoneBodyDirective } from '../../shared/components/layouts/dual-zone/dual-zone-body.directive';
import { DualZoneFooterDirective } from '../../shared/components/layouts/dual-zone/dual-zone-footer.directive';
import { DualZoneComponent } from '../../shared/components/layouts/dual-zone/dual-zone.component';
import { ButtonComponent } from '../../shared/components/ui/button/button.component';
import { PanelComponent } from '../../shared/components/ui/panel/panel.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomerRestService } from '../../core/rest/customer/customer-rest.service';
import { confirmPasswordValidator, matchValidator, PASSWORD_PATTERN } from '../../core/utils/form-inputs-validator';
import { NgIf } from '@angular/common';
import { catchError, throwError } from 'rxjs';
import { SnackBarService } from '../../core/services/snackbar.service';

@Component({
  selector: 'app-password-change',
  standalone: true,
  imports: [
    DualZoneComponent,
    DualZoneBodyDirective,
    DualZoneFooterDirective,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ButtonComponent,
    RouterLink,
    PanelComponent,
    TranslateModule,
    ReactiveFormsModule,
    NgIf,
  ],
  templateUrl: './password-change.component.html',
  styleUrl: './password-change.component.scss'
})
export class PasswordChangeComponent implements OnInit {

  hideOld = true;
  hide = true;
  hideRepeat = true;

  form!: FormGroup;

  constructor(private customerRestService: CustomerRestService,
              private translateService: TranslateService,
              private snackBarService: SnackBarService) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      oldPassword: new FormControl<string>('', [Validators.required]),
      password: new FormControl<string>('', [Validators.required, Validators.pattern(PASSWORD_PATTERN)]),
      rePassword: new FormControl<string>('', [Validators.required, matchValidator('password')])
    }, {validators: confirmPasswordValidator})
  }

  save(): void {

    if (this.form.invalid) {
      return;
    }

    const passwordChanges = {
      oldPassword: this.form.value.oldPassword,
      password: this.form.value.password,
    };

    this.customerRestService.updatePassword(passwordChanges.oldPassword, passwordChanges.password)
      .pipe(catchError((err) => {
        const message = this.customerRestService.getErrorMessage(err);
        this.snackBarService.showErrorMessage(message);
        return throwError(() => err);
      }))
      .subscribe(response => {
        if (response) {
          this.snackBarService.showSuccessMessage(this.translateService.instant('PASSWORD_CHANGE.UPDATE_SUCCESSFUL'));
        }
      })
  }

  getPatternMessage(pattern?:string) {
    return this.form.get('password')?.value.match(pattern)
  }
}
