import { Routes } from '@angular/router';
import { AccountComponent } from './views/account/account.component';
import { CartComponent } from './views/cart/cart.component';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { LoginComponent } from './views/login/login.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { PasswordChangeComponent } from './views/password-change/password-change.component';
import {
  PasswordResetConfirmationComponent
} from './views/password-reset-confirmation/password-reset-confirmation.component';
import { PasswordResetComponent } from './views/password-reset/password-reset.component';
import {
  PasswordSetNewConfirmationComponent
} from './views/password-set-new-confirmation/password-set-new-confirmation.component';
import { PasswordSetNewComponent } from './views/password-set-new/password-set-new.component';
import { PaymentConfirmationComponent } from './views/payment-confirmation/payment-confirmation.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { RegisterComponent } from './views/register/register.component';
import {
  RegistrationConfirmationComponent
} from './views/registration-confirmation/registration-confirmation.component';
import { TermsComponent } from './views/terms/terms.component';
import { TicketsComponent } from './views/tickets/tickets.component';
import { AuthenticatedUserCanActivateGuard } from "./shared/guards/authenticated-user-can-activate.guard";
import {
  AccountActivationFailed
} from './views/account-activation-failed/account-activation-failed.component';
import { PaymentInfoComponent } from './views/payment-info/payment-info.component';
import { CheckForUserGuard } from './shared/guards/check-for-user.guard';

export const routes: Routes = [
  {
    path: '',
    children: [
      {path: '', redirectTo: 'checkout', pathMatch: 'full'},
      {path: 'checkout', component: CheckoutComponent},
      {path: 'checkout/payment', component: CartComponent},
      {path: 'checkout/payment/confirmation', component: PaymentConfirmationComponent, data: {hasError: true}},
      {path: 'login', component: LoginComponent},
      {path: 'payment/info', component: PaymentInfoComponent},
      {path: 'register', component: RegisterComponent},
      {path: 'register/confirmation', component: RegistrationConfirmationComponent},
      {path: 'account/activation', component: AccountActivationFailed},
      {path: 'password', redirectTo: 'password/reset', pathMatch: 'full'},
      {path: 'password/reset', component: PasswordResetComponent},
      {path: 'password/reset/confirmation', component: PasswordResetConfirmationComponent},
      {path: 'password/set-new', component: PasswordSetNewComponent},
      {path: 'password/set-new/confirmation', component: PasswordSetNewConfirmationComponent},
      {path: 'terms', component: TermsComponent},
      {path: 'privacy', component: PrivacyComponent},
      {path: 'account', component: AccountComponent, canActivate: [AuthenticatedUserCanActivateGuard]},
      {path: 'account/password', component: PasswordChangeComponent, canActivate: [AuthenticatedUserCanActivateGuard]},
      {path: 'tickets', component: TicketsComponent, canActivate: [AuthenticatedUserCanActivateGuard]},
      {path: '**', component: NotFoundComponent}
    ],
    canActivate: [CheckForUserGuard],
  },
];
