import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [
    MatIcon,
    NgClass,
  ],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss'
})
export class IconComponent {
  @Input() name!: string;
  @Input() size: 'm' | 'l' | 'xl' | '2xl' = 'm';
  set: 'svg' = 'svg';
}
