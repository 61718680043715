import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild } from '@angular/core';
import { DualZoneBodyDirective } from './dual-zone-body.directive';
import { DualZoneFooterDirective } from './dual-zone-footer.directive';

@Component({
  selector: 'app-dual-zone',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgClass,
  ],
  templateUrl: './dual-zone.component.html',
  styleUrl: './dual-zone.component.scss',
})
export class DualZoneComponent {
  @ContentChild(DualZoneBodyDirective) bodyDirective?: DualZoneBodyDirective;
  @ContentChild(DualZoneFooterDirective) footerDirective?: DualZoneFooterDirective;
}
