import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANGUAGE, getLanguage, setLanguage } from '../utils/language.util';
import { BehaviorSubject, Observable } from 'rxjs';

const FLAGS_PATH = 'assets/images/';

export enum FlagsEnum {
  MN = `${FLAGS_PATH}flag-mn.svg`,
  DE = `${FLAGS_PATH}flag-de.svg`,
  EN = `${FLAGS_PATH}flag-uk.svg`,
  RU = `${FLAGS_PATH}flag-ru.svg`,
}

export enum LangCodesEnum {
  DE = 'de',
  EN = 'en',
  MN = 'mn',
  RU = 'ru'
}

export enum LangNamesEnum {
  DE = 'Deutsch',
  EN = 'English',
  MN = 'Crnogorski',
  RU = 'Русский'
}

export interface Lang {
  code: LangCodesEnum;
  name: LangNamesEnum;
  flag: FlagsEnum;
}

export const LANGUAGES_LIST: Lang[] = [
  {code: LangCodesEnum.MN, name: LangNamesEnum.MN, flag: FlagsEnum.MN},
  {code: LangCodesEnum.EN, name: LangNamesEnum.EN, flag: FlagsEnum.EN},
];

const languages = LANGUAGES_LIST;

const findLanguage = (langCode: string): Lang => {
  const languageMap: Map<string, Lang> = new Map<string, Lang>(LANGUAGES_LIST.map(lang => [lang.code, lang]));
  return languageMap.get(langCode) ?? languages[0];
}

const DEFAULT_LANGUAGE_OBJ = (): Lang => {
  return findLanguage(DEFAULT_LANGUAGE);
};

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  selectedLanguage$= new BehaviorSubject<Lang>(DEFAULT_LANGUAGE_OBJ());

  constructor(private translate: TranslateService) {
  }

  setLanguage(lang: LangCodesEnum, reloadApp: boolean): void {
    if (lang) {
      setLanguage(lang, reloadApp);
      this.translate.use(lang);
      this.selectedLanguage$.next(findLanguage(lang));
    }
  }

  getSelectedLanguage(): Observable<Lang> {
    return this.selectedLanguage$.asObservable();
  }

  getSelectedLanguageFromLocalStorage(): string {
    return getLanguage();
  }


}
