import { AsyncPipe, NgClass } from '@angular/common';
import { AfterViewInit, Component, ElementRef, inject, ViewChild, } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { LanguageSwitcherComponent } from '../../ui/language-switcher/language-switcher.component';
import { LogoComponent } from '../../ui/logo/logo.component';
import { UserMenuComponent } from '../../ui/user-menu/user-menu.component';
import { FooterInfoComponent } from "../footer-info/footer-info.component";
import { CheckoutTotalAmountService } from '../../../../views/checkout/checkout-total-amount.service';
import { TotalAmountComponent } from '../../ui/total-amount/total-amount.component';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, switchMap, take, throwError } from 'rxjs';
import { ButtonComponent } from '../../ui/button/button.component';
import { CartModel } from '../../../../core/rest/cart/model/cart.model';
import { CartRestService } from '../../../../core/rest/cart/cart-rest.service';
import { CustomerRestService } from '../../../../core/rest/customer/customer-rest.service';
import { SnackBarService } from '../../../../core/services/snackbar.service';
import { MatIcon } from '@angular/material/icon';
import { IconComponent } from '../../ui/icon/icon.component';
import { PanelComponent } from '../../ui/panel/panel.component';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    RouterOutlet,
    LogoComponent,
    LanguageSwitcherComponent,
    UserMenuComponent,
    NgClass,
    FooterInfoComponent,
    AsyncPipe,
    TotalAmountComponent,
    TranslateModule,
    ButtonComponent,
    RouterLink,
    MatIcon,
    IconComponent,
    PanelComponent,
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  providers: [CheckoutTotalAmountService],
})

export class MainComponent implements AfterViewInit {
  @ViewChild('mainIntersectionObserverTrigger') mainIntersectionObserverTrigger?: ElementRef<HTMLElement>;
  headerHasShadow = false;
  totalAmountService = inject(CheckoutTotalAmountService);
  cartRestService = inject(CartRestService);
  customerRestService = inject(CustomerRestService)
  router = inject(Router);
  snackBarService = inject(SnackBarService);

  ngAfterViewInit(): void {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        this.headerHasShadow = !entry.isIntersecting;
      });
    });
    if (this.mainIntersectionObserverTrigger) {
      observer.observe(this.mainIntersectionObserverTrigger.nativeElement);
    }
  }

  continueToCheckout() {
    this.totalAmountService.products$
      .pipe(
        take(1),
        switchMap((products) => {
          const productItems = products
            .filter(product => product.quantity > 0)
            .map(product => {
              return {idProduct: product.id, quantity: product.quantity}
            })

          const cart: CartModel = {
            items: productItems,
          }
          return this.cartRestService.create(cart)
            .pipe(
              catchError((err) => {
                const message = this.customerRestService.getErrorMessage(err);
                this.snackBarService.showErrorMessage(message);
                return throwError(() => err);
              }));
        })
      )
      .subscribe(() => {
        this.router.navigate(['checkout/payment']);
      });
  }
}
