import { NgClass, NgIf } from '@angular/common';
import { Component, Input, Self, ViewEncapsulation } from '@angular/core';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { AbstractControl, ControlValueAccessor, NgControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-phone-field',
  standalone: true,
  imports: [
    NgClass,
    NgxMatIntlTelInputComponent,
    ReactiveFormsModule,
    NgIf,
    TranslateModule,
    MatFormFieldModule,
  ],
  templateUrl: './phone-field.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./phone-field.component.scss']
})
export class PhoneFieldComponent implements ControlValueAccessor {
  @Input() isFullWide = true;
  @Input() placeholder = '';
  @Input() label = '';
  required!: boolean;
  private onChange!: (value: string) => void;

  constructor(@Self() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.required = this.isRequired(this.ngControl);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
  }

  isRequired(ngControl: NgControl): boolean {
    if (!ngControl) {
      return false;
    }
    if (ngControl.control && ngControl.control.validator) {
      const validator = ngControl.control.validator({} as AbstractControl);
      if (validator && validator?.['required']) {
        return true;
      }
    }
    return false;
  }
}
